import { useEffect } from 'react'

import PageBanner from '../../shared/components/pageBanner/pageBanner'
import './helpFaq.css'

const HelpFaq = () => {

	useEffect(()=>{
        window.scrollTo(0, 0)
    }, [])

	return (
		<div className="defaultLayout">
			<div className="wrapper" id="helpFaq">
				<PageBanner check={true} />
				<div className="faqs-block">
					<div className="container">
						<div className="mb-3 mb-md-5 d-flex flex-column flex-md-row justify-content-md-between">
							<h2 className="mb-3 mb-md-0">Frequently Asked Question</h2>
							<div className="d-flex align-items-center">
								<span className="mr-3">Sorted by:</span>
								<div>
									<select id="categories" className="field-des">
										<option>General</option>
										<option>Selling</option>
										<option>Buying</option>
									</select>
								</div>
							</div>
						</div>
						<div className="faqs-holder">
							<div id="accordion">
								<div className="card">
									<div className="card-header" id="headingOne">
										<h5 className="mb-0">
											<button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
												<span>Lorem Ipsum is simply dummy text of the printing and typesetting</span>
												<span className="arrow-icon ml-2">
													<i className="fa fa-angle-down"></i>
												</span>
											</button>
										</h5>
									</div>
									<div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
										<div className="card-body">
											Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.
										</div>
									</div>
								</div>
								<div className="card">
									<div className="card-header" id="headingTwo">
										<h5 className="mb-0">
											<button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
												<span>Lorem Ipsum is simply dummy text of the printing and typesetting</span>
												<span className="arrow-icon ml-2">
													<i className="fa fa-angle-down"></i>
												</span>
											</button>
										</h5>
									</div>
									<div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
										<div className="card-body">
											Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.
										</div>
									</div>
								</div>
								<div className="card">
									<div className="card-header" id="headingThree">
										<h5 className="mb-0">
											<button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
												<span>Lorem Ipsum is simply dummy text of the printing and typesetting</span>
												<span className="arrow-icon ml-2">
													<i className="fa fa-angle-down"></i>
												</span>
											</button>
										</h5>
									</div>
									<div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
										<div className="card-body">
											Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default HelpFaq
