import React from "react";
import routes from "./routes";
import { PrivateRoute } from "./privateRoute";
import { Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./App.css";

const App = (props) => {
  return (
    <React.Fragment>
      {/* <Suspense fallback={<FullPageLoader />}> */}
      <div className="wrapper">
        <Switch>
          {routes.map((route, index) => {
            return (
              <PrivateRoute
                key={index}
                path={route.path}
                exact={route.exact}
                access={true}
                component={(props) => (
                  <route.layout {...props}>
                    <route.component {...props} />
                  </route.layout>
                )}
              />
            );
          })}
        </Switch>
      </div>
      {/* </Suspense> */}
    </React.Fragment>
  );
};

export default App;
