import { useEffect } from 'react'

import img1 from '../../assets/images/img-01.jpg'
import img2 from '../../assets/images/img-02.jpg'

import PageBanner from '../../shared/components/pageBanner/pageBanner'

import './aboutPage.css'

const AboutPage = () => {

	useEffect(()=>{
        window.scrollTo(0, 0)
    }, [])

	return (
		<div className="wrapper">
			<PageBanner check={false} title="About us" content="Neque porro quisquam est qui dolorem ipsum." />
			<div className="about-block">
				<div className="container text-center">
					<h2 className="mb-3">About Livestock</h2>
					<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum..It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum.</p>
				</div>
			</div>
			<div className="mission-block">
				<div className="container">
					<div className="row align-items-md-center flex-row-reverse flex-md-row">
						<div className="col-md-4 pt-2 text-center">
							<div className="image-holder">
								<img alt="" className="img-fluid" src={img1} />
							</div>
						</div>
						<div className="col-md-4 pt-2 text-center">
							<div className="image-holder">
								<img alt="" className="img-fluid" src={img2} />
							</div>
						</div>
						<div className="col-md-4">
							<div className="d-flex flex-column">
								<h2 className="mb-3">Our mission</h2>
								<p>Our mission is to revolutionise the way the Australian cattle industry connects.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="buy-sell-policy">
				<div className="container">
					<div className="heading-block mb-5 text-center">
						<h2 className="mb-3">The Website</h2>
						<p>We are continually developing the tools our website offers to meet the demands of our industry and our customers. Our marketing toolbox currently includes:</p>
					</div>
					<div className="row">
						<div className="col-md-6 pt-2">
							<div className="policy-block">
								<h3 className="text-white">Buying</h3>
								<ul className="policy-points list-unstyled">
									<li className="mb-1 position-relative">Advanced search functionality to find cattle</li>
									<li className="mb-1 position-relative">Additional 'Search by map' option</li>
									<li className="mb-1 position-relative">Cattle Alert system to save buyers work and time</li>
									<li className="mb-1 position-relative">'Favourite' tool to help buyers compare</li>
								</ul>
							</div>
						</div>
						<div className="col-md-6 pt-2">
							<div className="policy-block">
								<h3 className="text-white">Selling</h3>
								<ul className="policy-points list-unstyled">
									<li className="mb-1 position-relative">Different listing types to suit seller's specific needs</li>
									<li className="mb-1 position-relative">Smart tools to save time eg: PDF Flyer</li>
									<li className="mb-1 position-relative">Ability to create your listing directly from any device</li>
									<li className="mb-1 position-relative">Social media sharing</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AboutPage
