import { BEFORE_SHARED } from '../types'
const initialState = {
}

export default function (state = initialState, action) {
    switch (action.type) {
        case BEFORE_SHARED: {
            return {
                ...state,
            }
        }
        default:
            return {
                ...state
            }
    }

}