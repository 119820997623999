import { Link } from "react-router-dom";

const createNewPwd = () => {
    return (
        <div className="col-12 wrapper main-bggrey p-0">
            <div className="col-12 inner-page-content login-page px-0 d-flex justify-content-center align-items-center ">
                <div id="login" className="col-sm-12  my-4">
                    <div className="login-container col-sm-8 col-md-6 col-lg-4 col-xl-4 m-auto bg-white boxshadow border-radius8">
                        <div id="login-row" className="row justify-content-center align-items-center ">
                            <div id="login-column" className="w-100">
                                <h3 className="text-center page-head font-weight-bold  txtcolor">Create New Password</h3>
                                <h5 className="col-md-10 px-md-4 m-auto text-center txt-lghtblue page-subhead  pb-2">Your new password must be different from
                                    previous used password</h5>
                                <div id="login-box" className="col-md-12 mt-4">
                                    <form id="login-form" className="form site-form" action="" method="post">
                                        <div className="form-group  mb-4">
                                            <input type="text" name="username" id="username" className="form-control input-style min-input" placeholder="New password" />
                                        </div>
                                        <div className="form-group position-relative  mb-2">
                                            <input type="text" name="password" id="password" className="form-control input-style min-input" placeholder="Confirm password" />
                                            <Link to={window.location.pathname} className="pwd-show">
                                                Show
                                            </Link>
                                        </div>
                                        <div className="form-group mt-4 mb-0">
                                            <button type="submit" className="btn  text-white w-100 btn-custom min-input mt-3" >
                                                <span>
                                                    Sign in
                                                </span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default createNewPwd;