import { useHistory } from "react-router-dom";

const PostNewListing = () => {
    const history = useHistory();
    return (
        <>
            <div className="dashContentHeader px-5 mb-3">
                <h5 className="dashHeading mb-0">Post New Listing</h5>
                <p className="text-lt mt-3 font-16">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
            </div>
            <div className="row dashboardCardRow px-5">
                <div className="col-lg-6 bg-white mt-5 shadow mb-3">
                    <form className="mg-login mt-4 px-3">
                        <div className="row mb-0">
                            <div className="col-md-12 mb-4 "><label>CHOOSE A CATEGORY </label>
                                <select id="inputState" className="form-control">
                                    <option selected>Machinery</option>
                                    <option>...</option>
                                </select>
                            </div>
                            <div className="col-md-12 mb-4"><label>CHOOSE A subCATEGORY </label>
                                <select id="inputState" className="form-control">
                                    <option selected>Cranes & Lifting</option>
                                    <option>...</option>
                                </select>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end mb-5">
                            <button type="button" onClick={() => history.push("/newProductForm")} className="mb-5 sub-button mt-4">Next Step</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default PostNewListing;