import Cards from "./cards/cards"
import Notifications from "./notifications/notifications"
import Table from "./table/table"

const UserDashboard = () => {
    return (
        <>
            <div className="dashContentHeader d-flex justify-content-between align-items-center">
                <h5 className="dashHeading mb-0">Dashboard</h5>
                <div className="new-btn">
                    <button onClick="location.href='post-new-listing.html'" className="sub-button">Post New Listing</button></div>
            </div>

            <div className="row dashboardCardRow">
                <div className="col-lg-8">
                        <Cards />
                    <div className="col-12 p-0">
                        <Table />
                    </div>
                </div>
                <div className="col-lg-4">
                    <Notifications />
                </div>
            </div>
        </>
    )
}

export default UserDashboard
