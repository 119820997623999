import SideBar from "./sideBar/sideBar";
import Content from "./content/content";
import "../commonLayout.css";
import '../../assets/css/custom-style.css'
import "../../assets/css/bid.css";

const UserDashboard = (props) => {
    return (
        <>
            <div className="commonLayout">
                <SideBar />
                <Content contentAsProp={props.children} />
            </div>
        </>
    )
}

export default UserDashboard
