import React from 'react'

const Notification = ({name, min, color}) => {
    return (
        <>
            <span className="d-flex mr-3">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="20" cy="20" r="20" fill={color} fill-opacity="0.45"/>
                    <path d="M15.008 14.976H16.87L19.936 20.282H20.048L23.114 14.976H24.99V25H23.114V20.058L23.226 18.378H23.114L20.566 22.872H19.432L16.87 18.378H16.758L16.87 20.058V25H15.008V14.976Z" fill="white"/>
                </svg>
            </span>
            <div className="media-body">
                <h5 className="mt-0 noti-heading mb-0"><span>{name},</span> has bid on your listing.</h5>
                <p className="noti-date mb-0">{min} min ago</p>
            </div>
        </>
    )
}

export default Notification
