import { Link } from "react-router-dom";
import "./pagination.css";

const Pagination = () => {
    return (
        <div className="product-pagination">
            <ul className="pagination">
                <li className="page-item"><Link className="page-link disabled" to={window.location.pathname}><i className="fa fa-angle-double-left" aria-hidden="true"></i></Link></li>
                <li className="page-item"><Link className="page-link" to={window.location.pathname}>Previous</Link></li>
                <li className="page-item"><Link className="page-link" to={window.location.pathname}>1</Link></li>
                <li className="page-item"><Link className="page-link" to={window.location.pathname}>2</Link></li>
                <li className="page-item"><Link className="page-link" to={window.location.pathname}>3</Link></li>
                <li className="page-item"><Link className="page-link" to={window.location.pathname}>4</Link></li>
                <li className="page-item"><Link className="page-link" to={window.location.pathname}>5</Link></li>
                <li className="page-item"><Link className="page-link" to={window.location.pathname}>Next</Link></li>
                <li className="page-item"><Link className="page-link" to={window.location.pathname}><i className="fa fa-angle-double-right" aria-hidden="true"></i></Link></li>
            </ul>
        </div>
    );
}

export default Pagination;