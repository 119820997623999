import { useEffect } from 'react';

import PageBanner from '../../shared/components/pageBanner/pageBanner';
import './contact.css';


const Contact = () => {

	useEffect(()=>{
        window.scrollTo(0, 0)
    }, [])

	return (
		<div className="wrapper">
			<PageBanner check={false} title="Contact Us" content="Neque porro quisquam est qui dolorem ipsum." />
			<div className="contact-form-block">
				<div className="container">
					<form className="contact-form bg-white position-relative p-3">
						<div className="row">
							<div className="col-md-6">
								<div className="form-group">
									<label for="first-name" className="form-label text-uppercase">First Name</label>
									<input id="first-name" className="form-control" type="text" placeholder="Type here..." />
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label for="last-name" className="form-label text-uppercase">Last Name</label>
									<input id="last-name" className="form-control" type="text" placeholder="Type here..." />
								</div>
							</div>
							<div className="col-md-12">
								<div className="form-group">
									<label for="email" className="form-label text-uppercase">Email</label>
									<input id="email" className="form-control" type="email" placeholder="Type here..." />
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label for="phone" className="form-label text-uppercase">Phone</label>
									<input id="phone" className="form-control" type="tel" placeholder="Type here..." />
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label for="pref-time" className="form-label text-uppercase">Select Preferred Time</label>
									<input id="pref-time" className="form-control" placeholder="Type here..." />
								</div>
							</div>
							<div className="col-md-12">
								<div className="form-group">
									<label for="message" className="form-label text-uppercase">Message</label>
									<textarea id="message" className="form-control" rows="5" cols="5" placeholder="Type Here..."></textarea>
								</div>
							</div>
							<div className="col-md-12">
								<div className="form-group">
									<button type="submit" className="btn btn-theme btn-large btn-custom">Send Message</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}

export default Contact
