import { useEffect } from "react";

import { Link } from "react-router-dom";
import './signUp.css'

const SignUp = () => {

    useEffect(()=>{
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="col-12 wrapper main-bggrey p-0">
            <div className="col-12 inner-page-content login-page signUp-100 px-0 d-flex justify-content-center align-items-center ">
                <div id="login" className="col-sm-12  my-4">
                    <div className="login-container col-sm-8 col-md-6 col-lg-4 col-xl-4 m-auto bg-white boxshadow border-radius8">
                        <div id="login-row" className="row justify-content-center align-items-center ">
                            <div id="login-column" className="w-100">
                                <h3 className="text-center page-head font-weight-bold  txtcolor">Sign Up</h3>
                                <h5 className="text-center txt-lghtblue page-subhead mb-4 pb-2">Enter your account details below</h5>
                                <div id="login-box" className="col-md-12">
                                    <form id="login-form" className="form site-form" action="" method="post">
                                        <div className="form-group mb-4">
                                            <input type="text" name="username" id="username" className="form-control input-style min-input" placeholder="First name" />
                                        </div>
                                        <div className="form-group mb-4">
                                            <input type="text" name="userlname" id="userlname" className="form-control input-style min-input" placeholder="Last name" />
                                        </div>
                                        <div className="form-group mb-4">
                                            <input type="email" name="userlname" id="userlname" className="form-control input-style min-input" placeholder="Email address" />
                                        </div>
                                        <div className="form-group position-relative  mb-4">
                                            <input type="text" name="password" id="password" className="form-control input-style min-input" placeholder="Password" />
                                            <Link to={window.location.pathname} className="pwd-show">
                                                Show
                                            </Link>
                                        </div>
                                        <div className="form-group mb-4">

                                            <button type="submit" className="btn  text-white w-100 btn-custom min-input mt-2" >
                                                <span>
                                                    Sign in
                                                </span>
                                            </button>
                                        </div>
                                    </form>
                                    <p className="w-100 text-center p-lght m-0">Already on Bid? <Link to="/login">Sign in</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignUp;