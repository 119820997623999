import "./header.css";
import logo from "../../../assets/images/logo.svg";
import { Link } from "react-router-dom";
import pc from "../../../assets/images/pc.png";

const Header = ({ loggedIn = true }) => {
    return (
        <header className="bg-white header py-2">
            <div className="container">
                <nav className="w-100  d-flex justify-content-between flex-wrap py-1 align-items-center">
                    <div className="bid-logo">
                        <Link className="navbar-brand" to={'/'}><img src={logo} alt="logo" /></Link>
                    </div>
                    {loggedIn ? (<div className="bid-ac">
                        <div className="dropdown">
                            <span><img src={pc} alt="" /> </span>
                            <button className="btn btn-secondary dropdown-toggle pr-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Marcus Atkins
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <Link className="dropdown-item" to={window.location.pathname}>Action</Link>
                                <Link className="dropdown-item" to={window.location.pathname}>Another action</Link>
                                <Link className="dropdown-item" to={window.location.pathname}>Something else here</Link>
                            </div>
                        </div>
                    </div>) : (<div className="bid-nav">
                        <ul className="list-unstyled d-flex m-0 align-items-center">
                            <li className="nav-item">
                                <Link id="btn-login" className={`btn ${window.location.pathname === "/login" ? "btn-custom" : ""}`} to="/login"> Log in </Link>
                            </li>
                            <li className="nav-item">
                                <Link id="btn-signup" className={`btn ${window.location.pathname === "/signup" ? "btn-custom" : ""}`} to="/signup"> Register </Link>
                            </li>
                        </ul>
                    </div>)}

                </nav>
            </div>
        </header>
    );
}

export default Header;