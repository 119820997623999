import React from 'react'

const Settings = () => {
  return (
    <>
      <div className="dashContentHeader">
        <h5 className="dashHeading mb-0">My Account</h5>
      </div>


      <div className="dashboardCardRow pb-3">
        <div className="col-xl-6 col-lg-10 p-0">  <h3 style={{ color: '#ff7e06' }}>Change Password</h3>
          <hr /></div>
        <form className="mg-login admin-pass mt-5 col-xl-6 col-lg-10 p-4 bg-white">
          <h2 className="dashheader-title mb-4">Password Settings</h2>
          <div className="row">

            <div className="col-md-6 mb-4"><label>Current Password</label>{" "}<span style={{ color: '#ff7e06' }}>*</span>
              <input type="text" className="form-control" id="" placeholder="Old password" />
            </div>
            <div className="col-md-6 mb-4 "><label>new password</label>{" "}<span style={{ color: '#ff7e06' }}>*</span>
              <input type="text" className="form-control" id="" placeholder="New password" />
            </div>

            <div className="col-md-6 mb-3"><label>Confirm new password</label>{" "}<span style={{ color: '#ff7e06' }}>*</span>
              <input type="text" className="form-control" id="" placeholder="Confirm new password" />
            </div>

          </div>
          <button className=" sub-button py-3">Save Changes</button>
        </form>
      </div>
    </>
  )
}

export default Settings
