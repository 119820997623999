import {useEffect} from 'react'
import "./home.css";
import HomeBanner from '../homeBanner/homeBanner'
import SearchForm from '../../shared/components/searchForm/searchForm';
import ProductItem from '../../shared/components/productItem/productItem';

import img1 from '../../assets/images/product-item-01.jpg'
import img2 from '../../assets/images/product-item-02.jpg'
import img3 from '../../assets/images/product-item-03.jpg'
import img4 from '../../assets/images/product-item-04.jpg'

const Home = () => {

    useEffect(()=>{
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="defaultHome">
            <HomeBanner />
            <SearchForm />
            <section className="product-listing-wrapper">
                <div className="container">
                    <div className="product-tab-wrap">
                        <div className="product-head-wrap d-flex flex-column flex-md-row align-items-center justify-content-between">
                            <h1>Cattle listing</h1>
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" data-toggle="tab" href="#tabs-1" role="tab">Show Cattle</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#tabs-2" role="tab">Dairy Cattle</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#tabs-3" role="tab">Beef Cattle</a>
                                </li>
                            </ul>
                        </div>
                        <div className="tab-content">
                            <div className="tab-pane active" id="tabs-1" role="tabpanel">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                        <ProductItem path={img1} title="18 Month Angus Bull" location="HARRISON,  Arkansas, US   72601" price="2500.00" />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                        <ProductItem path={img2} title="Hereford Homozygous Polled Bull" location="Long Beach,  California, US  90007" price="800.00" />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                        <ProductItem path={img3} title="Fall Yearling Simangus Bull" location="Easton,  Kansas, US  66020" price="2500.00" />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                        <ProductItem path={img4} title="Milking Shorthorn Heifers" location="San Jose,  California, US   95037" price="900.00" />
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane" id="tabs-2" role="tabpanel">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                        <ProductItem path={img1} title="18 Month Angus Bull" location="HARRISON,  Arkansas, US   72601" price="2500.00" />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                        <ProductItem path={img1} title="18 Month Angus Bull" location="HARRISON,  Arkansas, US   72601" price="2500.00" />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                        <ProductItem path={img1} title="18 Month Angus Bull" location="HARRISON,  Arkansas, US   72601" price="2500.00" />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                        <ProductItem path={img1} title="18 Month Angus Bull" location="HARRISON,  Arkansas, US   72601" price="2500.00" />
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane" id="tabs-3" role="tabpanel">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                        <ProductItem path={img1} title="18 Month Angus Bull" location="HARRISON,  Arkansas, US   72601" price="2500.00" />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                        <ProductItem path={img1} title="18 Month Angus Bull" location="HARRISON,  Arkansas, US   72601" price="2500.00" />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                        <ProductItem path={img1} title="18 Month Angus Bull" location="HARRISON,  Arkansas, US   72601" price="2500.00" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="product-tab-wrap">
                        <div className="product-head-wrap d-flex flex-column flex-md-row align-items-center justify-content-between">
                            <h1>Horses listing</h1>
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item mb-2">
                                    <a className="nav-link active" data-toggle="tab" href="#tabs-3" role="tab">Horses For Sports</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#tabs-4" role="tab">Show Horses</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#tabs-5" role="tab">Racing Horses</a>
                                </li>
                            </ul>
                        </div>
                        <div className="tab-content">
                            <div className="tab-pane active" id="tabs-3" role="tabpanel">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                        <ProductItem path={img1} title="18 Month Angus Bull" location="HARRISON,  Arkansas, US   72601" price="2500.00" />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                        <ProductItem path={img1} title="18 Month Angus Bull" location="HARRISON,  Arkansas, US   72601" price="2500.00" />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                        <ProductItem path={img1} title="18 Month Angus Bull" location="HARRISON,  Arkansas, US   72601" price="2500.00" />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                        <ProductItem path={img1} title="18 Month Angus Bull" location="HARRISON,  Arkansas, US   72601" price="2500.00" />
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane" id="tabs-4" role="tabpanel">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                        <ProductItem path={img1} title="18 Month Angus Bull" location="HARRISON,  Arkansas, US   72601" price="2500.00" />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                        <ProductItem path={img1} title="18 Month Angus Bull" location="HARRISON,  Arkansas, US   72601" price="2500.00" />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                        <ProductItem path={img1} title="18 Month Angus Bull" location="HARRISON,  Arkansas, US   72601" price="2500.00" />
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane" id="tabs-5" role="tabpanel">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                        <ProductItem path={img1} title="18 Month Angus Bull" location="HARRISON,  Arkansas, US   72601" price="2500.00" />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                        <ProductItem path={img1} title="18 Month Angus Bull" location="HARRISON,  Arkansas, US   72601" price="2500.00" />
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                                        <ProductItem path={img1} title="18 Month Angus Bull" location="HARRISON,  Arkansas, US   72601" price="2500.00" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Home;