import './homeBanner.css'

const HomeBanner = () => {
    return (
        <section className="main-banner">
            <div className="container">
                <div className="banner-content text-white text-center">
                    <h1>Where farmers come to buy and sell</h1>
                </div>
            </div>
        </section>
    )
}

export default HomeBanner
