import './userSettings.css'
import img from '../../assets/images/profile-info.png'

const UserSettings = () => {
  return (
    <div className="user-settings">
      <div className="dashContentHeader">
        <h5 className="dashHeading mb-0">My Listing</h5>
      </div>
      <div className="dashboardCardRow">
        <div className="row border-radius-4 box m-0">
          <div className="dashboard-tab col-lg-10">
            <ul id="myTabs" className="nav nav-pills nav-justified w-100 mb-4" role="tablist" data-tabs="tabs">
              <li><a href="#Settings" data-toggle="tab">Settings</a></li>
              <li><a href="#Change-Password" data-toggle="tab">Change Password</a></li>
            </ul>
            <div className="tab-content bg-white p-4 mt-4">
              <div role="tabpanel" className="tab-pane fade in active show" id="Settings">
                <h2 className="dashheader-title mb-4">Account Settings</h2>
                <div className="inner-form">
                  <div className="image-info d-flex py-4 flex-wrap justify-content-center justify-content-sm-start">
                    <div className="frame mr-5 mb-sm-0 mb-4">
                      <img alt="" src={img} />
                    </div>
                    <div className="d-flex align-items-center">  <button className="btn upload mr-4">Upload</button>
                      <button className="btn remove">Remove</button>
                    </div></div>
                  <hr />
                  <form className="mg-login mt-5">
                    <div className="row">
                      <div className="col-md-6 mb-4"><label>First name</label>
                        <input type="text" className="form-control" id="" placeholder="Marcus" />
                      </div>
                      <div className="col-md-6 mb-4 "><label>Last name</label>
                        <input type="text" className="form-control" id="" placeholder="Atkins" />
                      </div>

                      <div className="col-md-6 mb-4"><label>email address</label>
                        <input type="text" className="form-control" id="" placeholder="Marcus@domain.com" />
                      </div>
                      <div className="col-md-6 mb-4 "><label>phone number</label>
                        <input type="text" className="form-control" id="" placeholder="+13 6 12 34 56 78" />
                      </div>

                      <div className="col-md-6 mb-4"><label>address</label>
                        <input type="text" className="form-control" id="" placeholder="751 Green Hill Dr. Webster, NY 14580" />
                      </div>
                      <div className="col-md-6 mb-4 "><label>city</label>
                        <select id="inputState" className="form-control">
                          <option selected>Los Angeles</option>
                          <option>...</option>
                        </select>  </div>
                      <div className="col-md-6 mb-4"><label>country</label>
                        <select id="inputState" className="form-control">
                          <option selected>United States</option>
                          <option>...</option>
                        </select>
                      </div>
                      <div className="col-md-6 mb-4 "><label>postal code</label>
                        <input type="text" className="form-control" id="" placeholder="10018" />
                      </div>

                      <button className="mx-3 sub-button mt-4">Save Changes</button>
                    </div>
                  </form>
                </div>


              </div>
              <div role="tabpanel" className="tab-pane fade" id="Change-Password">
                <h2 className="dashheader-title mb-4">Password Settings</h2>
                <form className="mg-login mt-5">
                  <div className="row">
                    <div className="col-md-6 mb-4"><label>Current Password</label>{" "}<span style={{ color: '#ff7e06' }}>*</span>
                      <input type="text" className="form-control" id="" placeholder="Old password" />
                    </div>
                    <div className="col-md-6 mb-4 "><label>new password</label>{" "}<span style={{ color: '#ff7e06' }}>*</span>
                      <input type="text" className="form-control" id="" placeholder="New password" />
                    </div>

                    <div className="col-md-6 mb-3"><label>Confirm new password</label>{" "}<span style={{ color: '#ff7e06' }}>*</span>
                      <input type="text" className="form-control" id="" placeholder="Confirm new password" />
                    </div>

                  </div>
                  <button className=" sub-button">Save Changes</button>
                </form>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserSettings
