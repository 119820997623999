import "./footer.css";
import fLogo from "../../../assets/images/f-logo.svg";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer className="bg-black">
            <div className="footer-inner py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-6 footer-grid">
                            <div className="d-flex align-items-center">
                                <img src={fLogo} alt="logo" />
                                <p className="m-0 cpy-rght text-white mx-4">
                                    Bid.com Copyright © 2020. All rights reserved
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                            <ul className="list-unstyled d-flex m-0 align-items-center flex-wrap">
                                <li>
                                    <Link to={window.location.pathname}>
                                        Terms & Conditions
                                    </Link>
                                </li>
                                <li>
                                    <Link to={window.location.pathname}>
                                        Privacy Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/contact-us'}>
                                        Contact Us
                                    </Link>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;