import { useState } from "react";

const ClaimListing = () => {
    const [letter1, setLetter1] = useState("A");
    const [letter2, setLetter2] = useState("");
    const [letter3, setLetter3] = useState("");
    const [letter4, setLetter4] = useState("");
    const [letter5, setLetter5] = useState("");
    const [letter6, setLetter6] = useState("");

    return (
        <div id="claim-listing" className="modal fades" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-width" role="document">
                <div className="modal-content ">
                    <div className="modal-body p-5">
                        <h4 className="text-center  modal-heading">Enter invitation code to claim your page</h4>
                        <div className="code-box my-5 d-flex justify-content-center">
                            <span><input type="text" placeholder="-" value={letter1} onChange={(event) => setLetter1(event.target.value)} /></span>
                            <span><input type="text" placeholder="-" value={letter2} onChange={(event) => setLetter2(event.target.value)} /></span>
                            <span><input type="text" placeholder="-" value={letter3} onChange={(event) => setLetter3(event.target.value)} /></span>
                            <span><input type="text" placeholder="-" value={letter4} onChange={(event) => setLetter4(event.target.value)} /></span>
                            <span><input type="text" placeholder="-" value={letter5} onChange={(event) => setLetter5(event.target.value)} /></span>
                            <span><input type="text" placeholder="-" value={letter6} onChange={(event) => setLetter6(event.target.value)} /></span>
                        </div>
                        <div className="d-flex justify-content-center ">
                            <button type="button" data-dismiss="modal" data-toggle="modal" data-target="#claim-listing-verified" className="sub-button px-5">Verify</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClaimListing;