import './table.css'
import { Link } from 'react-router-dom'

const Table = () => {
  return (
    <>
      <h5 className="dashHeading mb-4 mt-5">Listing</h5>
      <div style={{ overflow: "auto" }}>
        <table className="table dashboard-listing customDashboardTable">
          <tr>
            <th>Listing Name</th>
            <th>Category</th>
            <th>Subcategory</th>
            <th>Price</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
          <tr>
            <td>
              <p className="orng-text mb-0">John Deere S680</p>
            </td>
            <td>Machinery</td>
            <td>Harvesting & Headers</td>
            <td>$50</td>
            <td><button className="btn soldBtn">Sold</button></td>

            <td><div className="dropdown">
              <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <svg width="22" height="6" viewBox="0 0 22 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="3" cy="3" r="3" fill="#C4C4C4" />
                  <circle cx="11" cy="3" r="3" fill="#C4C4C4" />
                  <circle cx="19" cy="3" r="3" fill="#C4C4C4" />
                </svg>
              </button>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                <Link className="dropdown-item" to={window.location.pathname}>Action</Link>
                <Link className="dropdown-item" to={window.location.pathname}>Another action</Link>
                <Link className="dropdown-item" to={window.location.pathname}>Something else here</Link>
              </div>
            </div></td>
          </tr>

          <tr>
            <td>
              <p className="orng-text mb-0">John Deere S680</p>
            </td>
            <td>Machinery</td>
            <td>Harvesting
              & Headers</td>
            <td>$50</td>

            <td><button className="btn ExpiredBtn">Expired</button></td>

            <td><div className="dropdown">
              <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <svg width="22" height="6" viewBox="0 0 22 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="3" cy="3" r="3" fill="#C4C4C4" />
                  <circle cx="11" cy="3" r="3" fill="#C4C4C4" />
                  <circle cx="19" cy="3" r="3" fill="#C4C4C4" />
                </svg>
              </button>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                <Link className="dropdown-item" to={window.location.pathname}>Action</Link>
                <Link className="dropdown-item" to={window.location.pathname}>Another action</Link>
                <Link className="dropdown-item" to={window.location.pathname}>Something else here</Link>
              </div>
            </div></td>
          </tr>
          <tr>
            <td>
              <p className="orng-text mb-0">John Deere S680</p>
            </td>
            <td>Machinery</td>
            <td>Harvesting
              & Headers</td>
            <td>$50</td>

            <td><button className="btn biddingBtn">Bidding</button></td>

            <td><div className="dropdown">
              <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <svg width="22" height="6" viewBox="0 0 22 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="3" cy="3" r="3" fill="#C4C4C4" />
                  <circle cx="11" cy="3" r="3" fill="#C4C4C4" />
                  <circle cx="19" cy="3" r="3" fill="#C4C4C4" />
                </svg>
              </button>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                <Link className="dropdown-item" to={window.location.pathname}>Action</Link>
                <Link className="dropdown-item" to={window.location.pathname}>Another action</Link>
                <Link className="dropdown-item" to={window.location.pathname}>Something else here</Link>
              </div>
            </div></td>
          </tr>
          <tr>
            <td>
              <p className="orng-text mb-0">John Deere S680</p>
            </td>
            <td>Machinery</td>
            <td>Harvesting
              & Headers</td>
            <td>$50</td>

            <td><button className="btn listedBtn">Listed</button></td>

            <td><div className="dropdown">
              <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <svg width="22" height="6" viewBox="0 0 22 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="3" cy="3" r="3" fill="#C4C4C4" />
                  <circle cx="11" cy="3" r="3" fill="#C4C4C4" />
                  <circle cx="19" cy="3" r="3" fill="#C4C4C4" />
                </svg>
              </button>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                <Link className="dropdown-item" to={window.location.pathname}>Action</Link>
                <Link className="dropdown-item" to={window.location.pathname}>Another action</Link>
                <Link className="dropdown-item" to={window.location.pathname}>Something else here</Link>
              </div>
            </div></td>
          </tr>
          <tr>
            <td>
              <p className="orng-text">John Deere S680</p>
            </td>
            <td>Machinery</td>
            <td>Harvesting
              & Headers</td>
            <td>$50</td>

            <td><button className="btn soldBtn">sold</button></td>

            <td><div className="dropdown">
              <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <svg width="22" height="6" viewBox="0 0 22 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="3" cy="3" r="3" fill="#C4C4C4" />
                  <circle cx="11" cy="3" r="3" fill="#C4C4C4" />
                  <circle cx="19" cy="3" r="3" fill="#C4C4C4" />
                </svg>
              </button>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                <Link className="dropdown-item" to={window.location.pathname}>Action</Link>
                <Link className="dropdown-item" to={window.location.pathname}>Another action</Link>
                <Link className="dropdown-item" to={window.location.pathname}>Something else here</Link>
              </div>
            </div></td>
          </tr>
          <tr>
            <td>
              <p className="orng-text mb-0">John Deere S680</p>
            </td>
            <td>Machinery</td>
            <td>Harvesting
              & Headers</td>
            <td>$50</td>

            <td><button className="btn soldBtn">sold</button></td>

            <td><div className="dropdown">
              <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <svg width="22" height="6" viewBox="0 0 22 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="3" cy="3" r="3" fill="#C4C4C4" />
                  <circle cx="11" cy="3" r="3" fill="#C4C4C4" />
                  <circle cx="19" cy="3" r="3" fill="#C4C4C4" />
                </svg>
              </button>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                <Link className="dropdown-item" to={window.location.pathname}>Action</Link>
                <Link className="dropdown-item" to={window.location.pathname}>Another action</Link>
                <Link className="dropdown-item" to={window.location.pathname}>Something else here</Link>
              </div>
            </div></td>
          </tr>
        </table>
      </div>

    </>
  )
}

export default Table
