import React, { Component } from 'react'

class Error extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <>
                <p>404 - Page Not FoundWe Are Sorry, But The Page You Requested Was Not Found.</p>
            </>
        )
    }
}

export default Error