const listItem = ({num, title, parentCategory, date, viewCont, updateCont, delCount}) => {
    return (
        <tr>
            <td>{num}</td>
            <td>{title}</td>
            <td>{parentCategory}</td>
            <td>{date}</td>
            <td>
                <div>
                    <button className="btn btn-link p-0 mr-3" onClick={() => viewCont(true, num)}>
                        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.267 6.66536C15.5836 2.75703 12.417 0.332031 9.0003 0.332031C5.58363 0.332031 2.41697 2.75703 0.733633 6.66536C0.687747 6.7705 0.664062 6.88398 0.664062 6.9987C0.664063 7.11341 0.687747 7.22689 0.733633 7.33203C2.41697 11.2404 5.58363 13.6654 9.0003 13.6654C12.417 13.6654 15.5836 11.2404 17.267 7.33203C17.3129 7.22689 17.3365 7.11341 17.3365 6.9987C17.3365 6.88398 17.3129 6.7705 17.267 6.66536ZM9.0003 11.9987C6.35863 11.9987 3.85863 10.0904 2.41697 6.9987C3.85863 3.90703 6.35863 1.9987 9.0003 1.9987C11.642 1.9987 14.142 3.90703 15.5836 6.9987C14.142 10.0904 11.642 11.9987 9.0003 11.9987ZM9.0003 3.66536C8.34103 3.66536 7.69656 3.86086 7.1484 4.22713C6.60024 4.5934 6.17299 5.114 5.9207 5.72309C5.66841 6.33217 5.6024 7.00239 5.73102 7.649C5.85963 8.2956 6.1771 8.88954 6.64328 9.35572C7.10945 9.82189 7.7034 10.1394 8.35 10.268C8.9966 10.3966 9.66682 10.3306 10.2759 10.0783C10.885 9.826 11.4056 9.39876 11.7719 8.8506C12.1381 8.30243 12.3336 7.65797 12.3336 6.9987C12.3336 6.11464 11.9824 5.2668 11.3573 4.64167C10.7322 4.01655 9.88435 3.66536 9.0003 3.66536ZM9.0003 8.66536C8.67066 8.66536 8.34843 8.56762 8.07435 8.38448C7.80027 8.20134 7.58665 7.94105 7.4605 7.6365C7.33435 7.33196 7.30135 6.99685 7.36566 6.67355C7.42997 6.35024 7.5887 6.05327 7.82179 5.82019C8.05488 5.5871 8.35185 5.42836 8.67515 5.36406C8.99845 5.29975 9.33356 5.33275 9.63811 5.4589C9.94265 5.58504 10.2029 5.79867 10.3861 6.07275C10.5692 6.34683 10.667 6.66906 10.667 6.9987C10.667 7.44072 10.4914 7.86465 10.1788 8.17721C9.86625 8.48977 9.44233 8.66536 9.0003 8.66536Z" fill="#FF7E06"/>
                        </svg>
                    </button>
                    <button className="btn btn-link  p-0 mr-3" onClick={() => updateCont(true, num)}>
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.7915 2.83203H2.83317C2.45745 2.83203 2.09711 2.98129 1.83144 3.24696C1.56576 3.51264 1.4165 3.87297 1.4165 4.2487V14.1654C1.4165 14.5411 1.56576 14.9014 1.83144 15.1671C2.09711 15.4328 2.45745 15.582 2.83317 15.582H12.7498C13.1256 15.582 13.4859 15.4328 13.7516 15.1671C14.0172 14.9014 14.1665 14.5411 14.1665 14.1654V9.20703" stroke="#FF7E06" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M13.104 1.77213C13.3858 1.49034 13.768 1.33203 14.1665 1.33203C14.565 1.33203 14.9472 1.49034 15.229 1.77213C15.5108 2.05393 15.6691 2.43612 15.6691 2.83463C15.6691 3.23315 15.5108 3.61534 15.229 3.89713L8.49984 10.6263L5.6665 11.3346L6.37484 8.5013L13.104 1.77213Z" stroke="#FF7E06" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>                
                    </button>
                    <button className="btn btn-link p-0" onClick={() => delCount(true, num)}>
                        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 4.19922H2.6H15.4" stroke="#F00000" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M4.99961 4.2V2.6C4.99961 2.17565 5.16818 1.76869 5.46824 1.46863C5.7683 1.16857 6.17526 1 6.59961 1H9.79961C10.224 1 10.6309 1.16857 10.931 1.46863C11.231 1.76869 11.3996 2.17565 11.3996 2.6V4.2M13.7996 4.2V15.4C13.7996 15.8243 13.631 16.2313 13.331 16.5314C13.0309 16.8314 12.624 17 12.1996 17H4.19961C3.77526 17 3.3683 16.8314 3.06824 16.5314C2.76818 16.2313 2.59961 15.8243 2.59961 15.4V4.2H13.7996Z" stroke="#F00000" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M6.60059 8.19922V12.9992" stroke="#F00000" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M9.7998 8.19922V12.9992" stroke="#F00000" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                    </button>
                </div>
            </td>
        </tr>
    )
}

export default listItem
