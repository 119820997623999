import { Link } from "react-router-dom";
import SearchForm from "../searchForm/searchForm";
import InnerPageBanner from "../../../shared/components/innerPageBanner/innerPageBanner";
import ProductItem from "../../../shared/components/productItem/productItem";
import "./searchResult2.css";
import Pagination from "../../../shared/components/pagination/pagination";
import img1 from '../../../assets/images/product-item-01.jpg'
import img2 from '../../../assets/images/product-item-02.jpg'
import img3 from '../../../assets/images/product-item-03.jpg'

const SearchResult = () => {
    return (
        <>
            <InnerPageBanner />
            <SearchForm />
            <section className="product-listing-wrapper inner-page-product-listing">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                            <div className="product-head-wrap d-flex flex-row align-items-center justify-content-between">
                                <h1>search result</h1>
                                <select name="sort-by" className="field-des" placeholder="Sort By">
                                    <option value="sort_buy">Sort By</option>
                                    <option value="recently_updated">Recently Updated</option>
                                    <option value="least_recently_updated">Least Recently Updated</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6">
                            <ProductItem path={img1} title="18 Month Angus Bull" location="HARRISON,  Arkansas, US   72601" price="2500.00" />
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6">
                            <ProductItem path={img2} title="Hereford Homozygous Polled Bull" location="Long Beach,  California, US  90007" price="800.00" />
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6">
                            <ProductItem path={img3} title="Fall Yearling Simangus Bull" location="Easton,  Kansas, US  66020" price="2500.00" />
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6">
                            <ProductItem path={img1} title="18 Month Angus Bull" location="HARRISON,  Arkansas, US   72601" price="2500.00" />
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6">
                            <ProductItem path={img2} title="Hereford Homozygous Polled Bull" location="Long Beach,  California, US  90007" price="800.00" />
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6">
                            <ProductItem path={img3} title="Fall Yearling Simangus Bull" location="Easton,  Kansas, US  66020" price="2500.00" />
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6">
                            <ProductItem path={img1} title="18 Month Angus Bull" location="HARRISON,  Arkansas, US   72601" price="2500.00" />
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6">
                            <ProductItem path={img2} title="Hereford Homozygous Polled Bull" location="Long Beach,  California, US  90007" price="800.00" />
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6">
                            <ProductItem path={img3} title="Fall Yearling Simangus Bull" location="Easton,  Kansas, US  66020" price="2500.00" />
                        </div>
                        <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                            <Pagination />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default SearchResult;