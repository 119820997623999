import Card from '../../../shared/components/card/card'


const Cards = () => {
    return (
        <div className="row">
            <div className="col-md-6 mb-3">
                <Card styleName="circleStyle1" title="Total No listings" num="246" lnk="/user-listing"/>
            </div>
            <div className="col-md-6">
                <Card styleName="circleStyle2" title="Total views" num="610" lnk="#"/>
            </div>
        </div>
    )
}

export default Cards
