import Cards from './cards/cards'
import Notifications from './notifications/notifications'

const Dashboard = () => {
    return (
        <>
            <div className="dashContentHeader">
                <h5 className="dashHeading mb-0">Dashboard</h5>
            </div>
            <div className="row dashboardCardRow">
                <div className="col-lg-8">
                    <Cards />
                </div>
                <div className="col-lg-4 mb-3">
                    <Notifications />
                </div>
            </div>
        </>
    )
}

export default Dashboard
