import { Link } from "react-router-dom";

const RegisteredUsers = () => {
    return (
        <>
            <div className="dashContentHeader d-flex justify-content-between align-items-center">
                <h5 className="dashHeading mb-0">Registered Users</h5>
                <div className="new-btn"><button className="sub-button">Post New Listing</button></div>
            </div>
            <div className="dashboardCardRow">
                <div className="input-group mb-4 search-menu">
                    <input type="text" className="form-control" placeholder="Search listing..." />
                    <div className="input-group-append">
                        <span className="input-group-text">
                            <i className="fa fa-search" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>
                <div className="listingTableRow">
                    <div className="listingTableHeader d-flex justify-content-between">
                        <div className="custom-control custom-checkbox pb-4">
                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                            <label className="custom-control-label" for="customCheck1">Showing 16 products</label>
                        </div>
                        <div className="d-flex justify-content-end">

                            <div className="d-flex shortListedDropdown">
                                <label className="mb-0">Sort by</label>
                                <div className="dropdown">
                                    <button className="border-0 btn-link dropdown-toggle card-link"
                                        type="button" id="dropdownMenu1" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        Newset update
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenu1">
                                        <Link className="dropdown-item" to={window.location.pathname}>Action</Link>
                                        <Link className="dropdown-item" to={window.location.pathname}>action2</Link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table customDashboardTable">
                            <tr>
                                <th>Name</th>
                                <th>Joined on</th>
                                <th>Role</th>
                                <th>Email</th>
                                <th>Actions</th>

                            </tr>
                            <tr>
                                <td className="d-flex align-items-center"><span className="circle-style mr-3 bg1">A</span>Adam Smith</td> <td>Dec 4, 2020</td>
                                <td>User</td><td className="orange-text">info@email.com</td>
                                <td><div className="dropdown">
                                    <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <svg width="22" height="6" viewBox="0 0 22 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="3" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="11" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="19" cy="3" r="3" fill="#C4C4C4" />
                                        </svg>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right table-action" aria-labelledby="dropdownMenuButton">
                                        <Link className="dropdown-item" to={window.location.pathname}>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.33398 2.66663H2.66732C2.3137 2.66663 1.97456 2.8071 1.72451 3.05715C1.47446 3.3072 1.33398 3.64634 1.33398 3.99996V13.3333C1.33398 13.6869 1.47446 14.0261 1.72451 14.2761C1.97456 14.5262 2.3137 14.6666 2.66732 14.6666H12.0007C12.3543 14.6666 12.6934 14.5262 12.9435 14.2761C13.1935 14.0261 13.334 13.6869 13.334 13.3333V8.66663" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M12.334 1.66665C12.5992 1.40144 12.9589 1.25244 13.334 1.25244C13.7091 1.25244 14.0688 1.40144 14.334 1.66665C14.5992 1.93187 14.7482 2.29158 14.7482 2.66665C14.7482 3.04173 14.5992 3.40144 14.334 3.66665L8.00065 9.99999L5.33398 10.6667L6.00065 7.99999L12.334 1.66665Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            Edit</Link>
                                        <Link className="dropdown-item" to={window.location.pathname}>
                                            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 4.20044H2.6H15.4" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4.99961 4.2V2.6C4.99961 2.17565 5.16818 1.76869 5.46824 1.46863C5.7683 1.16857 6.17526 1 6.59961 1H9.79961C10.224 1 10.6309 1.16857 10.931 1.46863C11.231 1.76869 11.3996 2.17565 11.3996 2.6V4.2M13.7996 4.2V15.4C13.7996 15.8243 13.631 16.2313 13.331 16.5314C13.0309 16.8314 12.624 17 12.1996 17H4.19961C3.77526 17 3.3683 16.8314 3.06824 16.5314C2.76818 16.2313 2.59961 15.8243 2.59961 15.4V4.2H13.7996Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M6.59961 8.19995V13" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M9.79883 8.19995V13" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            Delete</Link>
                                        <Link data-toggle="modal" data-target="#exampleModalCenter" className="dropdown-item" to={window.location.pathname}>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 5.33337C13.1046 5.33337 14 4.43794 14 3.33337C14 2.2288 13.1046 1.33337 12 1.33337C10.8954 1.33337 10 2.2288 10 3.33337C10 4.43794 10.8954 5.33337 12 5.33337Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4 10C5.10457 10 6 9.10457 6 8C6 6.89543 5.10457 6 4 6C2.89543 6 2 6.89543 2 8C2 9.10457 2.89543 10 4 10Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M12 14.6666C13.1046 14.6666 14 13.7712 14 12.6666C14 11.5621 13.1046 10.6666 12 10.6666C10.8954 10.6666 10 11.5621 10 12.6666C10 13.7712 10.8954 14.6666 12 14.6666Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M5.72656 9.00671L10.2799 11.66" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M10.2732 4.34009L5.72656 6.99342" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            Share</Link>

                                    </div>
                                </div></td>
                            </tr>
                            <tr>

                                <td className="d-flex align-items-center"><span className="circle-style mr-3 bg2">A</span>Adam Smith</td> <td>Dec 4, 2020</td>
                                <td>Admin</td><td>info@email.com</td>
                                <td>
                                    <div className="dropdown">
                                        <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <svg width="22" height="6" viewBox="0 0 22 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="3" cy="3" r="3" fill="#C4C4C4" />
                                                <circle cx="11" cy="3" r="3" fill="#C4C4C4" />
                                                <circle cx="19" cy="3" r="3" fill="#C4C4C4" />
                                            </svg>
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-right table-action" aria-labelledby="dropdownMenuButton">
                                            <Link className="dropdown-item" to={window.location.pathname}>
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.33398 2.66663H2.66732C2.3137 2.66663 1.97456 2.8071 1.72451 3.05715C1.47446 3.3072 1.33398 3.64634 1.33398 3.99996V13.3333C1.33398 13.6869 1.47446 14.0261 1.72451 14.2761C1.97456 14.5262 2.3137 14.6666 2.66732 14.6666H12.0007C12.3543 14.6666 12.6934 14.5262 12.9435 14.2761C13.1935 14.0261 13.334 13.6869 13.334 13.3333V8.66663" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M12.334 1.66665C12.5992 1.40144 12.9589 1.25244 13.334 1.25244C13.7091 1.25244 14.0688 1.40144 14.334 1.66665C14.5992 1.93187 14.7482 2.29158 14.7482 2.66665C14.7482 3.04173 14.5992 3.40144 14.334 3.66665L8.00065 9.99999L5.33398 10.6667L6.00065 7.99999L12.334 1.66665Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>

                                                Edit</Link>
                                            <Link className="dropdown-item" to={window.location.pathname}>
                                                <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 4.20044H2.6H15.4" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M4.99961 4.2V2.6C4.99961 2.17565 5.16818 1.76869 5.46824 1.46863C5.7683 1.16857 6.17526 1 6.59961 1H9.79961C10.224 1 10.6309 1.16857 10.931 1.46863C11.231 1.76869 11.3996 2.17565 11.3996 2.6V4.2M13.7996 4.2V15.4C13.7996 15.8243 13.631 16.2313 13.331 16.5314C13.0309 16.8314 12.624 17 12.1996 17H4.19961C3.77526 17 3.3683 16.8314 3.06824 16.5314C2.76818 16.2313 2.59961 15.8243 2.59961 15.4V4.2H13.7996Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M6.59961 8.19995V13" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M9.79883 8.19995V13" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>

                                                Delete</Link>
                                            <Link data-toggle="modal" data-target="#exampleModalCenter" className="dropdown-item" to={window.location.pathname}>
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12 5.33337C13.1046 5.33337 14 4.43794 14 3.33337C14 2.2288 13.1046 1.33337 12 1.33337C10.8954 1.33337 10 2.2288 10 3.33337C10 4.43794 10.8954 5.33337 12 5.33337Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M4 10C5.10457 10 6 9.10457 6 8C6 6.89543 5.10457 6 4 6C2.89543 6 2 6.89543 2 8C2 9.10457 2.89543 10 4 10Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M12 14.6666C13.1046 14.6666 14 13.7712 14 12.6666C14 11.5621 13.1046 10.6666 12 10.6666C10.8954 10.6666 10 11.5621 10 12.6666C10 13.7712 10.8954 14.6666 12 14.6666Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M5.72656 9.00671L10.2799 11.66" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M10.2732 4.34009L5.72656 6.99342" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>

                                                Share</Link>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="d-flex align-items-center"><span className="circle-style mr-3 bg3">A</span>Adam Smith</td> <td>Dec 4, 2020</td>
                                <td>Admin</td><td>info@email.com</td>
                                <td><div className="dropdown">
                                    <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <svg width="22" height="6" viewBox="0 0 22 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="3" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="11" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="19" cy="3" r="3" fill="#C4C4C4" />
                                        </svg>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right table-action" aria-labelledby="dropdownMenuButton">
                                        <Link className="dropdown-item" to={window.location.pathname}>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.33398 2.66663H2.66732C2.3137 2.66663 1.97456 2.8071 1.72451 3.05715C1.47446 3.3072 1.33398 3.64634 1.33398 3.99996V13.3333C1.33398 13.6869 1.47446 14.0261 1.72451 14.2761C1.97456 14.5262 2.3137 14.6666 2.66732 14.6666H12.0007C12.3543 14.6666 12.6934 14.5262 12.9435 14.2761C13.1935 14.0261 13.334 13.6869 13.334 13.3333V8.66663" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M12.334 1.66665C12.5992 1.40144 12.9589 1.25244 13.334 1.25244C13.7091 1.25244 14.0688 1.40144 14.334 1.66665C14.5992 1.93187 14.7482 2.29158 14.7482 2.66665C14.7482 3.04173 14.5992 3.40144 14.334 3.66665L8.00065 9.99999L5.33398 10.6667L6.00065 7.99999L12.334 1.66665Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            Edit</Link>
                                        <Link className="dropdown-item" to={window.location.pathname}>
                                            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 4.20044H2.6H15.4" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4.99961 4.2V2.6C4.99961 2.17565 5.16818 1.76869 5.46824 1.46863C5.7683 1.16857 6.17526 1 6.59961 1H9.79961C10.224 1 10.6309 1.16857 10.931 1.46863C11.231 1.76869 11.3996 2.17565 11.3996 2.6V4.2M13.7996 4.2V15.4C13.7996 15.8243 13.631 16.2313 13.331 16.5314C13.0309 16.8314 12.624 17 12.1996 17H4.19961C3.77526 17 3.3683 16.8314 3.06824 16.5314C2.76818 16.2313 2.59961 15.8243 2.59961 15.4V4.2H13.7996Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M6.59961 8.19995V13" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M9.79883 8.19995V13" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            Delete</Link>
                                        <Link data-toggle="modal" data-target="#exampleModalCenter" className="dropdown-item" to={window.location.pathname}>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 5.33337C13.1046 5.33337 14 4.43794 14 3.33337C14 2.2288 13.1046 1.33337 12 1.33337C10.8954 1.33337 10 2.2288 10 3.33337C10 4.43794 10.8954 5.33337 12 5.33337Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4 10C5.10457 10 6 9.10457 6 8C6 6.89543 5.10457 6 4 6C2.89543 6 2 6.89543 2 8C2 9.10457 2.89543 10 4 10Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M12 14.6666C13.1046 14.6666 14 13.7712 14 12.6666C14 11.5621 13.1046 10.6666 12 10.6666C10.8954 10.6666 10 11.5621 10 12.6666C10 13.7712 10.8954 14.6666 12 14.6666Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M5.72656 9.00671L10.2799 11.66" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M10.2732 4.34009L5.72656 6.99342" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            Share</Link>
                                    </div>
                                </div></td>
                            </tr>
                            <tr>
                                <td className="d-flex align-items-center"><span className="circle-style mr-3 bg4">A</span>Adam Smith</td> <td>Dec 4, 2020</td>
                                <td>User</td><td>info@email.com</td>
                                <td><div className="dropdown">
                                    <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <svg width="22" height="6" viewBox="0 0 22 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="3" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="11" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="19" cy="3" r="3" fill="#C4C4C4" />
                                        </svg>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right table-action" aria-labelledby="dropdownMenuButton">
                                        <Link className="dropdown-item" to={window.location.pathname}>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.33398 2.66663H2.66732C2.3137 2.66663 1.97456 2.8071 1.72451 3.05715C1.47446 3.3072 1.33398 3.64634 1.33398 3.99996V13.3333C1.33398 13.6869 1.47446 14.0261 1.72451 14.2761C1.97456 14.5262 2.3137 14.6666 2.66732 14.6666H12.0007C12.3543 14.6666 12.6934 14.5262 12.9435 14.2761C13.1935 14.0261 13.334 13.6869 13.334 13.3333V8.66663" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M12.334 1.66665C12.5992 1.40144 12.9589 1.25244 13.334 1.25244C13.7091 1.25244 14.0688 1.40144 14.334 1.66665C14.5992 1.93187 14.7482 2.29158 14.7482 2.66665C14.7482 3.04173 14.5992 3.40144 14.334 3.66665L8.00065 9.99999L5.33398 10.6667L6.00065 7.99999L12.334 1.66665Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            Edit</Link>
                                        <Link className="dropdown-item" to={window.location.pathname}>
                                            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 4.20044H2.6H15.4" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4.99961 4.2V2.6C4.99961 2.17565 5.16818 1.76869 5.46824 1.46863C5.7683 1.16857 6.17526 1 6.59961 1H9.79961C10.224 1 10.6309 1.16857 10.931 1.46863C11.231 1.76869 11.3996 2.17565 11.3996 2.6V4.2M13.7996 4.2V15.4C13.7996 15.8243 13.631 16.2313 13.331 16.5314C13.0309 16.8314 12.624 17 12.1996 17H4.19961C3.77526 17 3.3683 16.8314 3.06824 16.5314C2.76818 16.2313 2.59961 15.8243 2.59961 15.4V4.2H13.7996Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M6.59961 8.19995V13" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M9.79883 8.19995V13" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            Delete</Link>
                                        <Link data-toggle="modal" data-target="#exampleModalCenter" className="dropdown-item" to={window.location.pathname}>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 5.33337C13.1046 5.33337 14 4.43794 14 3.33337C14 2.2288 13.1046 1.33337 12 1.33337C10.8954 1.33337 10 2.2288 10 3.33337C10 4.43794 10.8954 5.33337 12 5.33337Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4 10C5.10457 10 6 9.10457 6 8C6 6.89543 5.10457 6 4 6C2.89543 6 2 6.89543 2 8C2 9.10457 2.89543 10 4 10Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M12 14.6666C13.1046 14.6666 14 13.7712 14 12.6666C14 11.5621 13.1046 10.6666 12 10.6666C10.8954 10.6666 10 11.5621 10 12.6666C10 13.7712 10.8954 14.6666 12 14.6666Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M5.72656 9.00671L10.2799 11.66" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M10.2732 4.34009L5.72656 6.99342" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            Share</Link>

                                    </div>
                                </div></td>

                            </tr>

                            <tr>

                                <td className="d-flex align-items-center"><span className="circle-style mr-3 bg1">A</span>Adam Smith</td> <td>Dec 4, 2020</td>
                                <td>Admin</td><td>info@email.com</td>
                                <td><div className="dropdown">
                                    <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <svg width="22" height="6" viewBox="0 0 22 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="3" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="11" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="19" cy="3" r="3" fill="#C4C4C4" />
                                        </svg>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right table-action" aria-labelledby="dropdownMenuButton">
                                        <Link className="dropdown-item" to={window.location.pathname}>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.33398 2.66663H2.66732C2.3137 2.66663 1.97456 2.8071 1.72451 3.05715C1.47446 3.3072 1.33398 3.64634 1.33398 3.99996V13.3333C1.33398 13.6869 1.47446 14.0261 1.72451 14.2761C1.97456 14.5262 2.3137 14.6666 2.66732 14.6666H12.0007C12.3543 14.6666 12.6934 14.5262 12.9435 14.2761C13.1935 14.0261 13.334 13.6869 13.334 13.3333V8.66663" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M12.334 1.66665C12.5992 1.40144 12.9589 1.25244 13.334 1.25244C13.7091 1.25244 14.0688 1.40144 14.334 1.66665C14.5992 1.93187 14.7482 2.29158 14.7482 2.66665C14.7482 3.04173 14.5992 3.40144 14.334 3.66665L8.00065 9.99999L5.33398 10.6667L6.00065 7.99999L12.334 1.66665Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            Edit</Link>
                                        <Link className="dropdown-item" to={window.location.pathname}>
                                            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 4.20044H2.6H15.4" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4.99961 4.2V2.6C4.99961 2.17565 5.16818 1.76869 5.46824 1.46863C5.7683 1.16857 6.17526 1 6.59961 1H9.79961C10.224 1 10.6309 1.16857 10.931 1.46863C11.231 1.76869 11.3996 2.17565 11.3996 2.6V4.2M13.7996 4.2V15.4C13.7996 15.8243 13.631 16.2313 13.331 16.5314C13.0309 16.8314 12.624 17 12.1996 17H4.19961C3.77526 17 3.3683 16.8314 3.06824 16.5314C2.76818 16.2313 2.59961 15.8243 2.59961 15.4V4.2H13.7996Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M6.59961 8.19995V13" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M9.79883 8.19995V13" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            Delete</Link>
                                        <Link data-toggle="modal" data-target="#exampleModalCenter" className="dropdown-item" to={window.location.pathname}>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 5.33337C13.1046 5.33337 14 4.43794 14 3.33337C14 2.2288 13.1046 1.33337 12 1.33337C10.8954 1.33337 10 2.2288 10 3.33337C10 4.43794 10.8954 5.33337 12 5.33337Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4 10C5.10457 10 6 9.10457 6 8C6 6.89543 5.10457 6 4 6C2.89543 6 2 6.89543 2 8C2 9.10457 2.89543 10 4 10Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M12 14.6666C13.1046 14.6666 14 13.7712 14 12.6666C14 11.5621 13.1046 10.6666 12 10.6666C10.8954 10.6666 10 11.5621 10 12.6666C10 13.7712 10.8954 14.6666 12 14.6666Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M5.72656 9.00671L10.2799 11.66" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M10.2732 4.34009L5.72656 6.99342" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            Share</Link>

                                    </div>
                                </div></td>

                            </tr>

                            <tr>

                                <td className="d-flex align-items-center"><span className="circle-style mr-3 bg1">A</span>Adam Smith</td> <td>Dec 4, 2020</td>
                                <td>Admin</td><td>info@email.com</td>
                                <td><div className="dropdown">
                                    <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <svg width="22" height="6" viewBox="0 0 22 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="3" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="11" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="19" cy="3" r="3" fill="#C4C4C4" />
                                        </svg>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right table-action" aria-labelledby="dropdownMenuButton">
                                        <Link className="dropdown-item" to={window.location.pathname}>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.33398 2.66663H2.66732C2.3137 2.66663 1.97456 2.8071 1.72451 3.05715C1.47446 3.3072 1.33398 3.64634 1.33398 3.99996V13.3333C1.33398 13.6869 1.47446 14.0261 1.72451 14.2761C1.97456 14.5262 2.3137 14.6666 2.66732 14.6666H12.0007C12.3543 14.6666 12.6934 14.5262 12.9435 14.2761C13.1935 14.0261 13.334 13.6869 13.334 13.3333V8.66663" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M12.334 1.66665C12.5992 1.40144 12.9589 1.25244 13.334 1.25244C13.7091 1.25244 14.0688 1.40144 14.334 1.66665C14.5992 1.93187 14.7482 2.29158 14.7482 2.66665C14.7482 3.04173 14.5992 3.40144 14.334 3.66665L8.00065 9.99999L5.33398 10.6667L6.00065 7.99999L12.334 1.66665Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            Edit</Link>
                                        <Link className="dropdown-item" to={window.location.pathname}>
                                            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 4.20044H2.6H15.4" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4.99961 4.2V2.6C4.99961 2.17565 5.16818 1.76869 5.46824 1.46863C5.7683 1.16857 6.17526 1 6.59961 1H9.79961C10.224 1 10.6309 1.16857 10.931 1.46863C11.231 1.76869 11.3996 2.17565 11.3996 2.6V4.2M13.7996 4.2V15.4C13.7996 15.8243 13.631 16.2313 13.331 16.5314C13.0309 16.8314 12.624 17 12.1996 17H4.19961C3.77526 17 3.3683 16.8314 3.06824 16.5314C2.76818 16.2313 2.59961 15.8243 2.59961 15.4V4.2H13.7996Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M6.59961 8.19995V13" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M9.79883 8.19995V13" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            Delete</Link>
                                        <Link data-toggle="modal" data-target="#exampleModalCenter" className="dropdown-item" to={window.location.pathname}>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 5.33337C13.1046 5.33337 14 4.43794 14 3.33337C14 2.2288 13.1046 1.33337 12 1.33337C10.8954 1.33337 10 2.2288 10 3.33337C10 4.43794 10.8954 5.33337 12 5.33337Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4 10C5.10457 10 6 9.10457 6 8C6 6.89543 5.10457 6 4 6C2.89543 6 2 6.89543 2 8C2 9.10457 2.89543 10 4 10Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M12 14.6666C13.1046 14.6666 14 13.7712 14 12.6666C14 11.5621 13.1046 10.6666 12 10.6666C10.8954 10.6666 10 11.5621 10 12.6666C10 13.7712 10.8954 14.6666 12 14.6666Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M5.72656 9.00671L10.2799 11.66" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M10.2732 4.34009L5.72656 6.99342" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            Share</Link>

                                    </div>
                                </div></td>

                            </tr>

                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RegisteredUsers;