import { Link } from "react-router-dom";

const UserListing = () => {
    return (
        <>
            <div className="dashContentHeader d-flex justify-content-between align-items-center">
                <h5 className="dashHeading mb-0">My Listing</h5>
                <div className="new-btn"><button onClick="location.href='post-new-listing.html'" className="sub-button">Post New Listing</button></div>
            </div>
            <div className="dashboardCardRow">
                <div className="input-group mb-4 search-menu">
                    <input type="text" className="form-control" placeholder="Search listing..." />
                    <div className="input-group-append">
                        <span className="input-group-text">
                            <i className="fa fa-search" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>
                <div className="listingTableRow">
                    <div className="listingTableHeader d-flex justify-content-between align-items-center">
                        <div className="custom-control custom-checkbox pb-4">
                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                            <label className="custom-control-label" for="customCheck1">Showing 16 products</label>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <button className="border-0 deleteButton mr-4">
                                <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.33398 3.66667V2.33333C3.33398 1.97971 3.47446 1.64057 3.72451 1.39052C3.97456 1.14048 4.3137 1 4.66732 1H7.33398C7.68761 1 8.02674 1.14048 8.27679 1.39052C8.52684 1.64057 8.66732 1.97971 8.66732 2.33333V3.66667M10.6673 3.66667V13C10.6673 13.3536 10.5268 13.6928 10.2768 13.9428C10.0267 14.1929 9.68761 14.3333 9.33398 14.3333H2.66732C2.3137 14.3333 1.97456 14.1929 1.72451 13.9428C1.47446 13.6928 1.33398 13.3536 1.33398 13V3.66667H10.6673Z" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M4.66602 7V11" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M7.33398 7V11" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                Delete
                            </button>
                            <div className="d-flex align-items-center shortListedDropdown">
                                <label className="mb-0">Sort by</label>
                                <div className="dropdown">
                                    <button className="border-0 btn-link dropdown-toggle card-link"
                                        type="button" id="dropdownMenu1" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        Newset update
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenu1">
                                        <Link className="dropdown-item" href="#!">Action</Link>
                                        <Link className="dropdown-item" href="#!">action2</Link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="table-outer">
                        <table className="table customDashboardTable">
                            <tr>
                                <th>Listing Name</th>
                                <th>Category</th>
                                <th>Subcategory</th>
                                <th>Price</th>
                                <th style={{ maxWidth: '100%' }}>Minimum Offer Amount</th>
                                <th>No. of
                                    Offers</th>
                                <th  >Status</th>
                                <th  >Actions</th>
                                <th  >Manage Bids</th>
                                <th  >Listing Expires on</th>
                                <th  >Created on</th>
                            </tr>


                            <tr>
                                <td>
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="customCheck2" checked />
                                        <label className="custom-control-label" for="customCheck2">John Deere S680</label>
                                    </div>
                                </td>
                                <td>Machinery</td>
                                <td>Harvesting
                                    & Headers</td>
                                <td>$50</td>
                                <td>20,000</td>
                                <td>12</td>
                                <td><button className="btn soldBtn">Sold</button></td>

                                <td><div className="dropdown">
                                    <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <svg width="22" height="6" viewBox="0 0 22 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="3" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="11" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="19" cy="3" r="3" fill="#C4C4C4" />
                                        </svg>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right table-action" aria-labelledby="dropdownMenuButton">
                                        <Link className="dropdown-item" href="#">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.33398 2.66663H2.66732C2.3137 2.66663 1.97456 2.8071 1.72451 3.05715C1.47446 3.3072 1.33398 3.64634 1.33398 3.99996V13.3333C1.33398 13.6869 1.47446 14.0261 1.72451 14.2761C1.97456 14.5262 2.3137 14.6666 2.66732 14.6666H12.0007C12.3543 14.6666 12.6934 14.5262 12.9435 14.2761C13.1935 14.0261 13.334 13.6869 13.334 13.3333V8.66663" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M12.334 1.66665C12.5992 1.40144 12.9589 1.25244 13.334 1.25244C13.7091 1.25244 14.0688 1.40144 14.334 1.66665C14.5992 1.93187 14.7482 2.29158 14.7482 2.66665C14.7482 3.04173 14.5992 3.40144 14.334 3.66665L8.00065 9.99999L5.33398 10.6667L6.00065 7.99999L12.334 1.66665Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            Edit</Link>
                                        <Link className="dropdown-item" href="#">
                                            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 4.20044H2.6H15.4" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4.99961 4.2V2.6C4.99961 2.17565 5.16818 1.76869 5.46824 1.46863C5.7683 1.16857 6.17526 1 6.59961 1H9.79961C10.224 1 10.6309 1.16857 10.931 1.46863C11.231 1.76869 11.3996 2.17565 11.3996 2.6V4.2M13.7996 4.2V15.4C13.7996 15.8243 13.631 16.2313 13.331 16.5314C13.0309 16.8314 12.624 17 12.1996 17H4.19961C3.77526 17 3.3683 16.8314 3.06824 16.5314C2.76818 16.2313 2.59961 15.8243 2.59961 15.4V4.2H13.7996Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M6.59961 8.19995V13" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M9.79883 8.19995V13" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            Delete</Link>
                                        <Link className="dropdown-item" href="#">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 5.33337C13.1046 5.33337 14 4.43794 14 3.33337C14 2.2288 13.1046 1.33337 12 1.33337C10.8954 1.33337 10 2.2288 10 3.33337C10 4.43794 10.8954 5.33337 12 5.33337Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4 10C5.10457 10 6 9.10457 6 8C6 6.89543 5.10457 6 4 6C2.89543 6 2 6.89543 2 8C2 9.10457 2.89543 10 4 10Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M12 14.6666C13.1046 14.6666 14 13.7712 14 12.6666C14 11.5621 13.1046 10.6666 12 10.6666C10.8954 10.6666 10 11.5621 10 12.6666C10 13.7712 10.8954 14.6666 12 14.6666Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M5.72656 9.00671L10.2799 11.66" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M10.2732 4.34009L5.72656 6.99342" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            Share</Link>
                                    </div>
                                </div></td>
                                <td className="orng-text text-decoration"><svg className="mr-2" width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.008 23.253H10.8129C10.7154 23.1555 -0.286879 20.2293 0.00573469 3.882V3.64791L1.50782 1.50208L1.80043 1.46307C1.9955 1.42405 11.008 0 11.008 0C11.008 0 20.0595 1.40454 20.2546 1.46307L20.4887 1.50208L21.9907 3.64791V3.882C22.1858 12.3483 19.2597 17.1666 16.7822 19.7026C14.0902 22.4727 11.2616 23.253 11.008 23.253ZM1.52732 4.17461C1.33225 18.103 9.66197 21.2632 11.0275 21.6924C12.432 21.2632 20.7032 18.0835 20.5277 4.11609L19.6108 2.80909C18.5964 2.575 14.5974 1.59962 11.047 1.50208C7.49663 1.59962 3.55611 2.575 2.48319 2.80909L1.52732 4.17461Z" fill="#01426A" />
                                    <path d="M15.4373 10.1635H7.26367V11.7631H15.4373V10.1635Z" fill="#01426A" />
                                    <path d="M15.4373 14.2015H7.26367V15.8011H15.4373V14.2015Z" fill="#01426A" />
                                    <path d="M15.4373 6.12537H7.26367V7.72499H15.4373V6.12537Z" fill="#01426A" />
                                    <path d="M7.22336 12.4068C6.44306 12.4068 5.7603 11.724 5.7603 10.9047C5.7603 10.1244 6.38454 9.40264 7.22336 9.40264H11.0664V8.29071H7.16484C5.70177 8.29071 4.58984 9.46116 4.58984 10.9242C4.58984 12.3873 5.7603 13.5577 7.16484 13.5577H11.0664V12.4068H7.22336Z" fill="#42C31D" />
                                </svg>
                                    Manage</td>
                                <td>Dec 14, 2020</td>
                                <td>Dec 4, 2020</td>
                            </tr>

                            <tr>
                                <td>
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="customCheck3" />
                                        <label className="custom-control-label" for="customCheck3">John Deere S680</label>
                                    </div>
                                </td>
                                <td>Machinery</td>
                                <td>Harvesting
                                    & Headers</td>
                                <td>$50</td>
                                <td>20,000</td>
                                <td>12</td>
                                <td><button className="btn ExpiredBtn">Expired</button></td>

                                <td><div className="dropdown">
                                    <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <svg width="22" height="6" viewBox="0 0 22 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="3" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="11" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="19" cy="3" r="3" fill="#C4C4C4" />
                                        </svg>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right table-action" aria-labelledby="dropdownMenuButton">
                                        <Link className="dropdown-item" href="#">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.33398 2.66663H2.66732C2.3137 2.66663 1.97456 2.8071 1.72451 3.05715C1.47446 3.3072 1.33398 3.64634 1.33398 3.99996V13.3333C1.33398 13.6869 1.47446 14.0261 1.72451 14.2761C1.97456 14.5262 2.3137 14.6666 2.66732 14.6666H12.0007C12.3543 14.6666 12.6934 14.5262 12.9435 14.2761C13.1935 14.0261 13.334 13.6869 13.334 13.3333V8.66663" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M12.334 1.66665C12.5992 1.40144 12.9589 1.25244 13.334 1.25244C13.7091 1.25244 14.0688 1.40144 14.334 1.66665C14.5992 1.93187 14.7482 2.29158 14.7482 2.66665C14.7482 3.04173 14.5992 3.40144 14.334 3.66665L8.00065 9.99999L5.33398 10.6667L6.00065 7.99999L12.334 1.66665Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            Edit</Link>
                                        <Link className="dropdown-item" href="#">
                                            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 4.20044H2.6H15.4" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4.99961 4.2V2.6C4.99961 2.17565 5.16818 1.76869 5.46824 1.46863C5.7683 1.16857 6.17526 1 6.59961 1H9.79961C10.224 1 10.6309 1.16857 10.931 1.46863C11.231 1.76869 11.3996 2.17565 11.3996 2.6V4.2M13.7996 4.2V15.4C13.7996 15.8243 13.631 16.2313 13.331 16.5314C13.0309 16.8314 12.624 17 12.1996 17H4.19961C3.77526 17 3.3683 16.8314 3.06824 16.5314C2.76818 16.2313 2.59961 15.8243 2.59961 15.4V4.2H13.7996Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M6.59961 8.19995V13" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M9.79883 8.19995V13" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            Delete</Link>
                                        <Link className="dropdown-item" href="#">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 5.33337C13.1046 5.33337 14 4.43794 14 3.33337C14 2.2288 13.1046 1.33337 12 1.33337C10.8954 1.33337 10 2.2288 10 3.33337C10 4.43794 10.8954 5.33337 12 5.33337Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4 10C5.10457 10 6 9.10457 6 8C6 6.89543 5.10457 6 4 6C2.89543 6 2 6.89543 2 8C2 9.10457 2.89543 10 4 10Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M12 14.6666C13.1046 14.6666 14 13.7712 14 12.6666C14 11.5621 13.1046 10.6666 12 10.6666C10.8954 10.6666 10 11.5621 10 12.6666C10 13.7712 10.8954 14.6666 12 14.6666Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M5.72656 9.00671L10.2799 11.66" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M10.2732 4.34009L5.72656 6.99342" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            Share</Link>
                                    </div>
                                </div></td>
                                <td className="blu-text text-decoration"><svg className="mr-2" width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.008 23.253H10.8129C10.7154 23.1555 -0.286879 20.2293 0.00573469 3.882V3.64791L1.50782 1.50208L1.80043 1.46307C1.9955 1.42405 11.008 0 11.008 0C11.008 0 20.0595 1.40454 20.2546 1.46307L20.4887 1.50208L21.9907 3.64791V3.882C22.1858 12.3483 19.2597 17.1666 16.7822 19.7026C14.0902 22.4727 11.2616 23.253 11.008 23.253ZM1.52732 4.17461C1.33225 18.103 9.66197 21.2632 11.0275 21.6924C12.432 21.2632 20.7032 18.0835 20.5277 4.11609L19.6108 2.80909C18.5964 2.575 14.5974 1.59962 11.047 1.50208C7.49663 1.59962 3.55611 2.575 2.48319 2.80909L1.52732 4.17461Z" fill="#01426A" />
                                    <path d="M15.4373 10.1635H7.26367V11.7631H15.4373V10.1635Z" fill="#01426A" />
                                    <path d="M15.4373 14.2015H7.26367V15.8011H15.4373V14.2015Z" fill="#01426A" />
                                    <path d="M15.4373 6.12537H7.26367V7.72499H15.4373V6.12537Z" fill="#01426A" />
                                    <path d="M7.22336 12.4068C6.44306 12.4068 5.7603 11.724 5.7603 10.9047C5.7603 10.1244 6.38454 9.40264 7.22336 9.40264H11.0664V8.29071H7.16484C5.70177 8.29071 4.58984 9.46116 4.58984 10.9242C4.58984 12.3873 5.7603 13.5577 7.16484 13.5577H11.0664V12.4068H7.22336Z" fill="#42C31D" />
                                </svg>
                                    Manage</td>
                                <td>Dec 14, 2020</td>
                                <td>Dec 4, 2020</td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="customCheck4" />
                                        <label className="custom-control-label" for="customCheck4">John Deere S680</label>
                                    </div>
                                </td>
                                <td>Machinery</td>
                                <td>Harvesting
                                    & Headers</td>
                                <td>$50</td>
                                <td>20,000</td>
                                <td>12</td>
                                <td><button className="btn biddingBtn">Bidding</button></td>

                                <td><div className="dropdown">
                                    <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <svg width="22" height="6" viewBox="0 0 22 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="3" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="11" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="19" cy="3" r="3" fill="#C4C4C4" />
                                        </svg>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right table-action" aria-labelledby="dropdownMenuButton">
                                        <Link className="dropdown-item" href="#">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.33398 2.66663H2.66732C2.3137 2.66663 1.97456 2.8071 1.72451 3.05715C1.47446 3.3072 1.33398 3.64634 1.33398 3.99996V13.3333C1.33398 13.6869 1.47446 14.0261 1.72451 14.2761C1.97456 14.5262 2.3137 14.6666 2.66732 14.6666H12.0007C12.3543 14.6666 12.6934 14.5262 12.9435 14.2761C13.1935 14.0261 13.334 13.6869 13.334 13.3333V8.66663" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M12.334 1.66665C12.5992 1.40144 12.9589 1.25244 13.334 1.25244C13.7091 1.25244 14.0688 1.40144 14.334 1.66665C14.5992 1.93187 14.7482 2.29158 14.7482 2.66665C14.7482 3.04173 14.5992 3.40144 14.334 3.66665L8.00065 9.99999L5.33398 10.6667L6.00065 7.99999L12.334 1.66665Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            Edit</Link>
                                        <Link className="dropdown-item" href="#">
                                            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 4.20044H2.6H15.4" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4.99961 4.2V2.6C4.99961 2.17565 5.16818 1.76869 5.46824 1.46863C5.7683 1.16857 6.17526 1 6.59961 1H9.79961C10.224 1 10.6309 1.16857 10.931 1.46863C11.231 1.76869 11.3996 2.17565 11.3996 2.6V4.2M13.7996 4.2V15.4C13.7996 15.8243 13.631 16.2313 13.331 16.5314C13.0309 16.8314 12.624 17 12.1996 17H4.19961C3.77526 17 3.3683 16.8314 3.06824 16.5314C2.76818 16.2313 2.59961 15.8243 2.59961 15.4V4.2H13.7996Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M6.59961 8.19995V13" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M9.79883 8.19995V13" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            Delete</Link>
                                        <Link className="dropdown-item" href="#">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 5.33337C13.1046 5.33337 14 4.43794 14 3.33337C14 2.2288 13.1046 1.33337 12 1.33337C10.8954 1.33337 10 2.2288 10 3.33337C10 4.43794 10.8954 5.33337 12 5.33337Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4 10C5.10457 10 6 9.10457 6 8C6 6.89543 5.10457 6 4 6C2.89543 6 2 6.89543 2 8C2 9.10457 2.89543 10 4 10Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M12 14.6666C13.1046 14.6666 14 13.7712 14 12.6666C14 11.5621 13.1046 10.6666 12 10.6666C10.8954 10.6666 10 11.5621 10 12.6666C10 13.7712 10.8954 14.6666 12 14.6666Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M5.72656 9.00671L10.2799 11.66" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M10.2732 4.34009L5.72656 6.99342" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            Share</Link>
                                    </div>
                                </div></td>
                                <td className="blu-text text-decoration"><svg className="mr-2" width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.008 23.253H10.8129C10.7154 23.1555 -0.286879 20.2293 0.00573469 3.882V3.64791L1.50782 1.50208L1.80043 1.46307C1.9955 1.42405 11.008 0 11.008 0C11.008 0 20.0595 1.40454 20.2546 1.46307L20.4887 1.50208L21.9907 3.64791V3.882C22.1858 12.3483 19.2597 17.1666 16.7822 19.7026C14.0902 22.4727 11.2616 23.253 11.008 23.253ZM1.52732 4.17461C1.33225 18.103 9.66197 21.2632 11.0275 21.6924C12.432 21.2632 20.7032 18.0835 20.5277 4.11609L19.6108 2.80909C18.5964 2.575 14.5974 1.59962 11.047 1.50208C7.49663 1.59962 3.55611 2.575 2.48319 2.80909L1.52732 4.17461Z" fill="#01426A" />
                                    <path d="M15.4373 10.1635H7.26367V11.7631H15.4373V10.1635Z" fill="#01426A" />
                                    <path d="M15.4373 14.2015H7.26367V15.8011H15.4373V14.2015Z" fill="#01426A" />
                                    <path d="M15.4373 6.12537H7.26367V7.72499H15.4373V6.12537Z" fill="#01426A" />
                                    <path d="M7.22336 12.4068C6.44306 12.4068 5.7603 11.724 5.7603 10.9047C5.7603 10.1244 6.38454 9.40264 7.22336 9.40264H11.0664V8.29071H7.16484C5.70177 8.29071 4.58984 9.46116 4.58984 10.9242C4.58984 12.3873 5.7603 13.5577 7.16484 13.5577H11.0664V12.4068H7.22336Z" fill="#42C31D" />
                                </svg>
                                    Manage</td>
                                <td>Dec 14, 2020</td>
                                <td>Dec 4, 2020</td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="customCheck5" checked />
                                        <label className="custom-control-label" for="customCheck5">John Deere S680</label>
                                    </div>
                                </td>
                                <td>Machinery</td>
                                <td>Harvesting
                                    & Headers</td>
                                <td>$50</td>
                                <td>20,000</td>
                                <td>12</td>
                                <td><button className="btn listedBtn">Listed</button></td>

                                <td><div className="dropdown">
                                    <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <svg width="22" height="6" viewBox="0 0 22 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="3" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="11" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="19" cy="3" r="3" fill="#C4C4C4" />
                                        </svg>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right table-action" aria-labelledby="dropdownMenuButton">
                                        <Link className="dropdown-item" href="#">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.33398 2.66663H2.66732C2.3137 2.66663 1.97456 2.8071 1.72451 3.05715C1.47446 3.3072 1.33398 3.64634 1.33398 3.99996V13.3333C1.33398 13.6869 1.47446 14.0261 1.72451 14.2761C1.97456 14.5262 2.3137 14.6666 2.66732 14.6666H12.0007C12.3543 14.6666 12.6934 14.5262 12.9435 14.2761C13.1935 14.0261 13.334 13.6869 13.334 13.3333V8.66663" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M12.334 1.66665C12.5992 1.40144 12.9589 1.25244 13.334 1.25244C13.7091 1.25244 14.0688 1.40144 14.334 1.66665C14.5992 1.93187 14.7482 2.29158 14.7482 2.66665C14.7482 3.04173 14.5992 3.40144 14.334 3.66665L8.00065 9.99999L5.33398 10.6667L6.00065 7.99999L12.334 1.66665Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            Edit</Link>
                                        <Link className="dropdown-item" href="#">
                                            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 4.20044H2.6H15.4" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4.99961 4.2V2.6C4.99961 2.17565 5.16818 1.76869 5.46824 1.46863C5.7683 1.16857 6.17526 1 6.59961 1H9.79961C10.224 1 10.6309 1.16857 10.931 1.46863C11.231 1.76869 11.3996 2.17565 11.3996 2.6V4.2M13.7996 4.2V15.4C13.7996 15.8243 13.631 16.2313 13.331 16.5314C13.0309 16.8314 12.624 17 12.1996 17H4.19961C3.77526 17 3.3683 16.8314 3.06824 16.5314C2.76818 16.2313 2.59961 15.8243 2.59961 15.4V4.2H13.7996Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M6.59961 8.19995V13" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M9.79883 8.19995V13" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            Delete</Link>
                                        <Link className="dropdown-item" href="#">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 5.33337C13.1046 5.33337 14 4.43794 14 3.33337C14 2.2288 13.1046 1.33337 12 1.33337C10.8954 1.33337 10 2.2288 10 3.33337C10 4.43794 10.8954 5.33337 12 5.33337Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4 10C5.10457 10 6 9.10457 6 8C6 6.89543 5.10457 6 4 6C2.89543 6 2 6.89543 2 8C2 9.10457 2.89543 10 4 10Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M12 14.6666C13.1046 14.6666 14 13.7712 14 12.6666C14 11.5621 13.1046 10.6666 12 10.6666C10.8954 10.6666 10 11.5621 10 12.6666C10 13.7712 10.8954 14.6666 12 14.6666Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M5.72656 9.00671L10.2799 11.66" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M10.2732 4.34009L5.72656 6.99342" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            Share</Link>
                                    </div>
                                </div></td>
                                <td className="blu-text text-decoration"><svg className="mr-2" width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.008 23.253H10.8129C10.7154 23.1555 -0.286879 20.2293 0.00573469 3.882V3.64791L1.50782 1.50208L1.80043 1.46307C1.9955 1.42405 11.008 0 11.008 0C11.008 0 20.0595 1.40454 20.2546 1.46307L20.4887 1.50208L21.9907 3.64791V3.882C22.1858 12.3483 19.2597 17.1666 16.7822 19.7026C14.0902 22.4727 11.2616 23.253 11.008 23.253ZM1.52732 4.17461C1.33225 18.103 9.66197 21.2632 11.0275 21.6924C12.432 21.2632 20.7032 18.0835 20.5277 4.11609L19.6108 2.80909C18.5964 2.575 14.5974 1.59962 11.047 1.50208C7.49663 1.59962 3.55611 2.575 2.48319 2.80909L1.52732 4.17461Z" fill="#01426A" />
                                    <path d="M15.4373 10.1635H7.26367V11.7631H15.4373V10.1635Z" fill="#01426A" />
                                    <path d="M15.4373 14.2015H7.26367V15.8011H15.4373V14.2015Z" fill="#01426A" />
                                    <path d="M15.4373 6.12537H7.26367V7.72499H15.4373V6.12537Z" fill="#01426A" />
                                    <path d="M7.22336 12.4068C6.44306 12.4068 5.7603 11.724 5.7603 10.9047C5.7603 10.1244 6.38454 9.40264 7.22336 9.40264H11.0664V8.29071H7.16484C5.70177 8.29071 4.58984 9.46116 4.58984 10.9242C4.58984 12.3873 5.7603 13.5577 7.16484 13.5577H11.0664V12.4068H7.22336Z" fill="#42C31D" />
                                </svg>
                                    Manage</td>
                                <td>Dec 14, 2020</td>
                                <td>Dec 4, 2020</td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="customCheck7" />
                                        <label className="custom-control-label" for="customCheck7">John Deere S680</label>
                                    </div>
                                </td>
                                <td>Machinery</td>
                                <td>Harvesting
                                    & Headers</td>
                                <td>$50</td>
                                <td>20,000</td>
                                <td>12</td>
                                <td><button className="btn soldBtn">sold</button></td>

                                <td><div className="dropdown">
                                    <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <svg width="22" height="6" viewBox="0 0 22 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="3" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="11" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="19" cy="3" r="3" fill="#C4C4C4" />
                                        </svg>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right table-action" aria-labelledby="dropdownMenuButton">
                                        <Link className="dropdown-item" href="#">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.33398 2.66663H2.66732C2.3137 2.66663 1.97456 2.8071 1.72451 3.05715C1.47446 3.3072 1.33398 3.64634 1.33398 3.99996V13.3333C1.33398 13.6869 1.47446 14.0261 1.72451 14.2761C1.97456 14.5262 2.3137 14.6666 2.66732 14.6666H12.0007C12.3543 14.6666 12.6934 14.5262 12.9435 14.2761C13.1935 14.0261 13.334 13.6869 13.334 13.3333V8.66663" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M12.334 1.66665C12.5992 1.40144 12.9589 1.25244 13.334 1.25244C13.7091 1.25244 14.0688 1.40144 14.334 1.66665C14.5992 1.93187 14.7482 2.29158 14.7482 2.66665C14.7482 3.04173 14.5992 3.40144 14.334 3.66665L8.00065 9.99999L5.33398 10.6667L6.00065 7.99999L12.334 1.66665Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            Edit</Link>
                                        <Link className="dropdown-item" href="#">
                                            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 4.20044H2.6H15.4" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4.99961 4.2V2.6C4.99961 2.17565 5.16818 1.76869 5.46824 1.46863C5.7683 1.16857 6.17526 1 6.59961 1H9.79961C10.224 1 10.6309 1.16857 10.931 1.46863C11.231 1.76869 11.3996 2.17565 11.3996 2.6V4.2M13.7996 4.2V15.4C13.7996 15.8243 13.631 16.2313 13.331 16.5314C13.0309 16.8314 12.624 17 12.1996 17H4.19961C3.77526 17 3.3683 16.8314 3.06824 16.5314C2.76818 16.2313 2.59961 15.8243 2.59961 15.4V4.2H13.7996Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M6.59961 8.19995V13" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M9.79883 8.19995V13" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            Delete</Link>
                                        <Link className="dropdown-item" href="#">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 5.33337C13.1046 5.33337 14 4.43794 14 3.33337C14 2.2288 13.1046 1.33337 12 1.33337C10.8954 1.33337 10 2.2288 10 3.33337C10 4.43794 10.8954 5.33337 12 5.33337Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4 10C5.10457 10 6 9.10457 6 8C6 6.89543 5.10457 6 4 6C2.89543 6 2 6.89543 2 8C2 9.10457 2.89543 10 4 10Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M12 14.6666C13.1046 14.6666 14 13.7712 14 12.6666C14 11.5621 13.1046 10.6666 12 10.6666C10.8954 10.6666 10 11.5621 10 12.6666C10 13.7712 10.8954 14.6666 12 14.6666Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M5.72656 9.00671L10.2799 11.66" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M10.2732 4.34009L5.72656 6.99342" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            Share</Link>
                                    </div>
                                </div></td>
                                <td className="blu-text text-decoration"><svg className="mr-2" width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.008 23.253H10.8129C10.7154 23.1555 -0.286879 20.2293 0.00573469 3.882V3.64791L1.50782 1.50208L1.80043 1.46307C1.9955 1.42405 11.008 0 11.008 0C11.008 0 20.0595 1.40454 20.2546 1.46307L20.4887 1.50208L21.9907 3.64791V3.882C22.1858 12.3483 19.2597 17.1666 16.7822 19.7026C14.0902 22.4727 11.2616 23.253 11.008 23.253ZM1.52732 4.17461C1.33225 18.103 9.66197 21.2632 11.0275 21.6924C12.432 21.2632 20.7032 18.0835 20.5277 4.11609L19.6108 2.80909C18.5964 2.575 14.5974 1.59962 11.047 1.50208C7.49663 1.59962 3.55611 2.575 2.48319 2.80909L1.52732 4.17461Z" fill="#01426A" />
                                    <path d="M15.4373 10.1635H7.26367V11.7631H15.4373V10.1635Z" fill="#01426A" />
                                    <path d="M15.4373 14.2015H7.26367V15.8011H15.4373V14.2015Z" fill="#01426A" />
                                    <path d="M15.4373 6.12537H7.26367V7.72499H15.4373V6.12537Z" fill="#01426A" />
                                    <path d="M7.22336 12.4068C6.44306 12.4068 5.7603 11.724 5.7603 10.9047C5.7603 10.1244 6.38454 9.40264 7.22336 9.40264H11.0664V8.29071H7.16484C5.70177 8.29071 4.58984 9.46116 4.58984 10.9242C4.58984 12.3873 5.7603 13.5577 7.16484 13.5577H11.0664V12.4068H7.22336Z" fill="#42C31D" />
                                </svg>
                                    Manage</td>
                                <td>Dec 14, 2020</td>
                                <td>Dec 4, 2020</td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="customCheck8" />
                                        <label className="custom-control-label" for="customCheck8">John Deere S680</label>
                                    </div>
                                </td>
                                <td>Machinery</td>
                                <td>Harvesting
                                    & Headers</td>
                                <td>$50</td>
                                <td>20,000</td>
                                <td>12</td>
                                <td><button className="btn soldBtn">sold</button></td>

                                <td><div className="dropdown">
                                    <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <svg width="22" height="6" viewBox="0 0 22 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="3" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="11" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="19" cy="3" r="3" fill="#C4C4C4" />
                                        </svg>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right table-action" aria-labelledby="dropdownMenuButton">
                                        <Link className="dropdown-item" href="#">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.33398 2.66663H2.66732C2.3137 2.66663 1.97456 2.8071 1.72451 3.05715C1.47446 3.3072 1.33398 3.64634 1.33398 3.99996V13.3333C1.33398 13.6869 1.47446 14.0261 1.72451 14.2761C1.97456 14.5262 2.3137 14.6666 2.66732 14.6666H12.0007C12.3543 14.6666 12.6934 14.5262 12.9435 14.2761C13.1935 14.0261 13.334 13.6869 13.334 13.3333V8.66663" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M12.334 1.66665C12.5992 1.40144 12.9589 1.25244 13.334 1.25244C13.7091 1.25244 14.0688 1.40144 14.334 1.66665C14.5992 1.93187 14.7482 2.29158 14.7482 2.66665C14.7482 3.04173 14.5992 3.40144 14.334 3.66665L8.00065 9.99999L5.33398 10.6667L6.00065 7.99999L12.334 1.66665Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            Edit</Link>
                                        <Link className="dropdown-item" href="#">
                                            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 4.20044H2.6H15.4" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4.99961 4.2V2.6C4.99961 2.17565 5.16818 1.76869 5.46824 1.46863C5.7683 1.16857 6.17526 1 6.59961 1H9.79961C10.224 1 10.6309 1.16857 10.931 1.46863C11.231 1.76869 11.3996 2.17565 11.3996 2.6V4.2M13.7996 4.2V15.4C13.7996 15.8243 13.631 16.2313 13.331 16.5314C13.0309 16.8314 12.624 17 12.1996 17H4.19961C3.77526 17 3.3683 16.8314 3.06824 16.5314C2.76818 16.2313 2.59961 15.8243 2.59961 15.4V4.2H13.7996Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M6.59961 8.19995V13" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M9.79883 8.19995V13" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            Delete</Link>
                                        <Link className="dropdown-item" href="#">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 5.33337C13.1046 5.33337 14 4.43794 14 3.33337C14 2.2288 13.1046 1.33337 12 1.33337C10.8954 1.33337 10 2.2288 10 3.33337C10 4.43794 10.8954 5.33337 12 5.33337Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4 10C5.10457 10 6 9.10457 6 8C6 6.89543 5.10457 6 4 6C2.89543 6 2 6.89543 2 8C2 9.10457 2.89543 10 4 10Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M12 14.6666C13.1046 14.6666 14 13.7712 14 12.6666C14 11.5621 13.1046 10.6666 12 10.6666C10.8954 10.6666 10 11.5621 10 12.6666C10 13.7712 10.8954 14.6666 12 14.6666Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M5.72656 9.00671L10.2799 11.66" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M10.2732 4.34009L5.72656 6.99342" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                            Share</Link>
                                    </div>
                                </div></td>
                                <td className="blu-text text-decoration"><svg className="mr-2" width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.008 23.253H10.8129C10.7154 23.1555 -0.286879 20.2293 0.00573469 3.882V3.64791L1.50782 1.50208L1.80043 1.46307C1.9955 1.42405 11.008 0 11.008 0C11.008 0 20.0595 1.40454 20.2546 1.46307L20.4887 1.50208L21.9907 3.64791V3.882C22.1858 12.3483 19.2597 17.1666 16.7822 19.7026C14.0902 22.4727 11.2616 23.253 11.008 23.253ZM1.52732 4.17461C1.33225 18.103 9.66197 21.2632 11.0275 21.6924C12.432 21.2632 20.7032 18.0835 20.5277 4.11609L19.6108 2.80909C18.5964 2.575 14.5974 1.59962 11.047 1.50208C7.49663 1.59962 3.55611 2.575 2.48319 2.80909L1.52732 4.17461Z" fill="#01426A" />
                                    <path d="M15.4373 10.1635H7.26367V11.7631H15.4373V10.1635Z" fill="#01426A" />
                                    <path d="M15.4373 14.2015H7.26367V15.8011H15.4373V14.2015Z" fill="#01426A" />
                                    <path d="M15.4373 6.12537H7.26367V7.72499H15.4373V6.12537Z" fill="#01426A" />
                                    <path d="M7.22336 12.4068C6.44306 12.4068 5.7603 11.724 5.7603 10.9047C5.7603 10.1244 6.38454 9.40264 7.22336 9.40264H11.0664V8.29071H7.16484C5.70177 8.29071 4.58984 9.46116 4.58984 10.9242C4.58984 12.3873 5.7603 13.5577 7.16484 13.5577H11.0664V12.4068H7.22336Z" fill="#42C31D" />
                                </svg>
                                    Manage</td>
                                <td>Dec 14, 2020</td>
                                <td>Dec 4, 2020</td>
                            </tr>
                        </table></div>
                </div>
            </div>
        </>
    );
}

export default UserListing
