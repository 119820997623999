import './forgotPassword.css'
const forgotPassword = () => {
    return (
        <div className="col-12 wrapper main-bggrey p-0">
            <div className="col-12 inner-page-content fPassword-100 login-page px-0 d-flex justify-content-center align-items-center ">
                <div id="login" className="col-sm-12  my-4">
                    <div className="login-container col-sm-8 col-md-6 col-lg-4 col-xl-4 m-auto bg-white boxshadow border-radius8">
                        <div id="login-row" className="row justify-content-center align-items-center ">
                            <div id="login-column" className="w-100">
                                <h3 className="text-center page-head font-weight-bold  txtcolor">Forgot Password?</h3>
                                <h5 className="text-center txt-lghtblue page-subhead mb-4 pb-2">Enter your details to recieve a reset link</h5>
                                <div id="login-box" className="col-md-12">
                                    <form id="login-form" className="form site-form" action="" method="post">

                                        <div className="form-group mb-4">
                                            <input type="email" name="userlname" id="userlname" className="form-control input-style min-input" placeholder="Email address" />
                                        </div>

                                        <div className="form-group m-0">
                                            <button type="submit" className="btn  text-white w-100 btn-custom min-input mt-2" onclick="window.location.href='mail-sent.html'; return false;" >
                                                <span>
                                                    Send Reset Link
                                                </span>
                                            </button>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default forgotPassword;