//Importing components
import ProductDetails from "./components/productDetails/productDetails";
import RegisteredUsers from "./components/registeredUsers/registeredUsers";
import AdminListing from "./components/adminListing/adminListing";
import PostNewListing from "./components/postNewListing/postNewListing";
import NewProductForm from "./components/newProductForm/newProductForm";
import SearchResult1 from "./components/searchResult/searchResult1/searchResult1";
import SearchResult2 from "./components/searchResult/searchResult2/searchResult2";
import SearchResult3 from "./components/searchResult/searchResult3/searchResult3";
import Home from "./components/home/home";
import Login from "./components/login/login";
import CreateNewPwd from "./components/createNewPwd/createNewPwd";
import ForgotPassword from "./components/forgotPassword/forgotPassword";
import MailSent from "./components/mailSent/mailSent";
import SignUp from "./components/signUp/signUp";
import CategoryListing from "./components/categoryListing/categoryListing";
import SubCategoryListing from "./components/subCategoryList/subCategoryList";
import Settings from "./components/settings/settings";
import DashboardComponent from "./components/dashboard/dashboard";
import UserDashboardComponent from "./components/userDashboard/userDashboard";
import UserSettings from "./components/userSettings/userSettings";
import AboutPage from "./components/aboutPage/aboutPage";
import HelpFaq from "./components/helpFaq/helpFaq";
import Contact from "./components/contact/contact";
import UserListing from "./components/userListing/userListing";

//Importing shared components
import Error from "./shared/components/error/error";

//Importing layouts
import Default from "./layouts/default/default";
import UserDashboard from "./layouts/userDashboard/userDashboard";
import Unauth from "./layouts/unauth/unauth";
import Dashboard from "./layouts/dashboard/dashboard";

const routes = [
  {
    path: "/",
    access: true,
    exact: true,
    name: "Home",
    layout: Default,
    component: Home,
  },
  {
    path: "/search-result/1",
    access: true,
    exact: true,
    name: "SearchResult1",
    layout: Default,
    component: SearchResult1,
  },
  {
    path: "/search-result/2",
    access: true,
    exact: true,
    name: "SearchResult2",
    layout: Default,
    component: SearchResult2,
  },
  {
    path: "/search-result/3",
    access: true,
    exact: true,
    name: "SearchResult3",
    layout: Default,
    component: SearchResult3,
  },
  {
    path: "/contact-us",
    access: true,
    exact: true,
    name: "Contact Us",
    layout: Default,
    component: Contact,
  },
  {
    path: "/help",
    access: true,
    exact: true,
    name: "HelpFaq",
    layout: Default,
    component: HelpFaq,
  },
  {
    path: "/about-us",
    access: true,
    exact: true,
    name: "About Us",
    layout: Default,
    component: AboutPage,
  },
  {
    path: "/user-settings",
    access: true,
    exact: true,
    name: "UserSettings",
    layout: UserDashboard,
    component: UserSettings,
  },
  {
    path: "/user-dashboard",
    access: true,
    exact: true,
    name: "UserDashboard",
    layout: UserDashboard,
    component: UserDashboardComponent,
  },
  {
    path: "/dashboard",
    access: true,
    exact: true,
    name: "Dashboard",
    layout: Dashboard,
    component: DashboardComponent,
  },
  {
    path: "/settings",
    access: true,
    exact: true,
    name: "settings",
    layout: Dashboard,
    component: Settings,
  },
  {
    path: "/category",
    access: true,
    exact: true,
    name: "categoryListing",
    layout: Dashboard,
    component: CategoryListing,
  },
  {
    path: "/sub-category",
    access: true,
    exact: true,
    name: "categoryListing",
    layout: Dashboard,
    component: SubCategoryListing,
  },

  {
    path: "/registered-users",
    access: true,
    exact: true,
    name: "registeredUsers",
    layout: Dashboard,
    component: RegisteredUsers,
  },
  {
    path: "/admin-listing",
    access: true,
    exact: true,
    name: "adminListing",
    layout: Dashboard,
    component: AdminListing,
  },
  {
    path: "/post-new-listing",
    access: true,
    exact: true,
    name: "postNewListing",
    layout: Dashboard,
    component: PostNewListing,
  },
  {
    path: "/newProductForm",
    access: true,
    exact: true,
    name: "newProductForm",
    layout: Dashboard,
    component: NewProductForm,
  },
  {
    path: "/login",
    access: true,
    exact: true,
    name: "login",
    layout: Unauth,
    component: Login,
  },
  {
    path: "/createNewPwd",
    access: true,
    exact: true,
    name: "createNewPwd",
    layout: Unauth,
    component: CreateNewPwd,
  },
  {
    path: "/forgotPassword",
    access: true,
    exact: true,
    name: "forgotPassword",
    layout: Unauth,
    component: ForgotPassword,
  },
  {
    path: "/mailSent",
    access: true,
    exact: true,
    name: "mailSent",
    layout: Unauth,
    component: MailSent,
  },
  {
    path: "/signUp",
    access: true,
    exact: true,
    name: "signUp",
    layout: Unauth,
    component: SignUp,
  },
  {
    path: "/productDetails",
    access: true,
    exact: true,
    name: "productDetails",
    layout: Unauth,
    component: ProductDetails,
  },
  {
    path: "/user-listing",
    access: true,
    exact: true,
    name: "AdminListing",
    layout: UserDashboard,
    component: UserListing,
  },
  {
    path: "/*",
    access: true,
    exact: true,
    name: "Erorr",
    layout: Unauth,
    component: Error,
  },
];

export default routes;
