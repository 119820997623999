import "./innerPageBanner.css";

const InnerPageBanner = () => {
    return (
        <section className="inner-page-banner">
            <div className="container">
                <div className="banner-content">
                    <h1>Search keyword</h1>
                </div>
            </div>
        </section>
    );
}

export default InnerPageBanner;