import InnerPageBanner from "../../../shared/components/innerPageBanner/innerPageBanner";
import ProductItem from '../../../shared/components/productItem/productItem';
import "./searchResult3.css";
import { Link } from "react-router-dom";
import Pagination from "../../../shared/components/pagination/pagination";
import img1 from '../../../assets/images/product-item-01.jpg'
import img2 from '../../../assets/images/product-item-02.jpg'
import img3 from '../../../assets/images/product-item-03.jpg'


const SearchResult3 = () => {
    return (
        <>
            <InnerPageBanner />
            <section className="product-filter-wrapper filter-page-des">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                            <div className="filter-box">
                                <div className="product-head-wrap d-flex flex-column align-items-start">
                                    <h1>Refine Search</h1>
                                    <div className="filter-fields-wrap w-100">
                                        <form className="search-form" action="" method="post">
                                            <div className="form-group">
                                                <label for="keyword">Keyword</label>
                                                <div className="keyword-field d-flex flex-row align-items-center justify-content-between">
                                                    <input type="text" className="form-control field-des" placeholder="Refine Keyword" />
                                                    <button type="button" className="btn btn-custom ml-2">Update</button>
                                                </div>
                                            </div>
                                            <div className="form-group d-flex align-items-center custom-w">
                                                <span className="input-group-addon">
                                                    <img alt="" src={require("../../../assets/images/category.png").default} className="img-fluid" />
                                                </span>
                                                <select id="categories" name="categories" className="field-des " form="categoriesform">
                                                    <option value="">Categories</option>
                                                    <option value="">livestocks</option>
                                                    <option value="">Machinery</option>
                                                    <option value="">Services</option>
                                                    <option value="">Vehicles</option>
                                                </select>
                                            </div>
                                            <div className="form-group d-flex align-items-center custom-w">
                                                <span className="input-group-addon">
                                                    <img alt="" src={require("../../../assets/images/category.png").default} className="img-fluid" />
                                                </span>
                                                <select id="categories" name="categories" className="field-des " form="categoriesform">
                                                    <option value="">Subcategories</option>
                                                    <option value="">category 1</option>
                                                    <option value="">category 2</option>
                                                </select>
                                            </div>
                                            <div className="form-group d-flex align-items-center custom-w">
                                                <span className="input-group-addon">
                                                    <img alt="" src={require("../../../assets/images/pin.png").default} className="img-fluid" />
                                                </span>
                                                <select id="location" name="locations" className="field-des" form="locationform">
                                                    <option value="">Location</option>
                                                    <option value="">location 1</option>
                                                    <option value="">location 2</option>
                                                </select>
                                            </div>
                                            <div className="form-group d-flex align-items-center custom-w">
                                                <span className="input-group-addon">
                                                    <img alt="" src={require("../../../assets/images/arrows.png").default} className="img-fluid" />
                                                </span>
                                                <select id="distance" name="distance" className="field-des" form="distanceform">
                                                    <option value="">100kms</option>
                                                    <option value="">80kms/option&gt;
                                                    </option><option value="">70kms</option>
                                                </select>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                            <div className="product-listing-wrapper inner-page-product-listing">
                                <div className="product-head-wrap d-flex flex-column align-items-center justify-content-between">
                                    <div className="filter-holder d-flex flex-row align-items-center justify-content-between w-100">
                                        <div className="filter-clear-box d-flex align-items-center">
                                            <h1>Your filters</h1>
                                            <span className="ml-2">Clear all</span>
                                        </div>
                                        <div className="sorted-label-box">
                                            <label for="sorted_by">Sorted by:</label>
                                            <select name="sort-by" className="field-des" placeholder="Sort By">
                                                <option value="sort_buy">Newest</option>
                                                <option value="recently_updated">Recently Updated</option>
                                                <option value="least_recently_updated">Least Recently Updated</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="filter-result-box d-flex align-items-center justify-content-start w-100">
                                        <span>Livestock<i className="fa fa-times"></i></span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <ProductItem check={true} path={img1} title="18 Month Angus Bull" location="HARRISON,  Arkansas, US   72601" price="2500.00" />
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <ProductItem check={true} path={img2} title="Hereford Homozygous Polled Bull" location="Long Beach,  California, US  90007" price="800.00" />
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <ProductItem check={true} path={img3} title="Fall Yearling Simangus Bull" location="Easton,  Kansas, US  66020" price="2500.00" />
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <ProductItem check={true} path={img1} title="18 Month Angus Bull" location="HARRISON,  Arkansas, US   72601" price="2500.00" />
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <ProductItem check={true} path={img2} title="Hereford Homozygous Polled Bull" location="Long Beach,  California, US  90007" price="800.00" />
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                                        <Pagination />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default SearchResult3;