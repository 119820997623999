import { Link } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import "./header.css";

const Header = () => {
    return (
        <header id="DefaultHeader" className="bg-white header">
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-light main-nav fill w-100  d-flex justify-content-between flex-wrap align-items-center">
                    <div className="bid-logo">
                        <Link className="navbar-brand" to={'/'}><img src={logo} alt="logo" /></Link>
                    </div>
                    <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse bid-nav justify-content-end" id="navbarResponsive">
                        <ul className="navbar-nav list-unstyled align-items-center">
                            <li className="nav-item">
                                <Link className={`nav-link ${window.location.pathname === "/" ? "active" : ""}`} to="/">home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${window.location.pathname === "/about-us" ? "active" : ""}`} to="/about-us">about</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${window.location.pathname === "/help" ? "active" : ""}`} to="/help">help/faq</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${window.location.pathname === "/contact-us" ? "active" : ""}`} to="/contact-us">contact</Link>
                            </li>
                            <li className="nav-item mbl-text-center">
                                <Link className="btn btn-theme" to="/login"> Login </Link>
                            </li>
                            <li className="nav-item mbl-text-center">
                                <Link className="btn btn-theme btn-custom" to="/signup"> Register </Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    );
}

export default Header;