import React from 'react'
import { connect } from 'react-redux'
import "./unauth.css";
import Header from "./header/header";
import Footer from "./footer/footer";
import "../commonLayout.css";
import "./unauth.css";

const Unauth = (props) => {
    return (
        <div className="commonLayout">
            <Header loggedIn={false} />
            {props.children}
            <Footer />
        </div>
    );
}

// export default Unauth
const mapStateToProps = state => ({
    shared: state.shared
})
export default connect(mapStateToProps, {})(Unauth)
