import React from 'react'
import Card from '../../../shared/components/card/card'

const Cards = () => {
    return (
        <>
            <div className="row">
                <div className="col-md-6 mb-3">
                    <Card styleName="circleStyle1" title="Total No listings" num="610" lnk="/admin-listing"/>
                </div>
                <div className="col-md-6">
                    <Card styleName="circleStyle2" title="Total views" num="510" lnk="/admin-listing"/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 mb-3">
                    <Card styleName="circleStyle3" title="Registered Users" num="9964" lnk="/registered-users"/>
                </div>
                <div className="col-md-6">
                    <Card styleName="circleStyle4" title="Total Revenue" num="$610" lnk="#"/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 mb-3">
                    <Card styleName="circleStyle5" title="Total No of Categories" num="164" lnk="/category"/>
                </div>
                <div className="col-md-6">
                    <Card styleName="circleStyle5" title="Total No of Subcategories" num="430" lnk="/sub-category"/>
                </div>
            </div>
        </>
    )
}

export default Cards
