import React from 'react'

const content = ({contentAsProp}) => {
    return (
        <div className="mainContentWrapper vh-100">
            {contentAsProp}
        </div> 
    )
}

export default content
