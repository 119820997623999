import ListItem from "./listItem/listItem"
import { useState } from "react"
import './subCategoryList.css'

const SubCategoryList = () => {


    const[getViewClass, setViewClass] = useState('hideModal')
    const[getUpdateClass, setUpdateClass] = useState('hideModal')
    const[getDelClass, setDelClass] = useState('hideModal')

    const viewList = (check, id) =>{
        if(check){
            setViewClass('viewModal')
        }
    }

    const updateList = (check, id) =>{
        if(check){
            setUpdateClass('viewModal')
        }
    }

    const delList = (check, id) =>{
        if(check){
            setDelClass('viewModal')
        }
    }


    return (
        <>
        <div className="dashContentHeader d-flex justify-content-between align-items-center">
                <div>
                    <h5 className="dashHeading mb-0">Subcategory</h5>
                </div>
                <div>
                    <button className="sub-button" data-toggle="modal" data-target="#addSubCategory">Add Subcategory</button>
                </div>

        </div>
        <div className="categoryListingTable bg-white p-6 table-responsive">
                <table className="table table-striped"> 
                    <tr>
                        <th id="sr">S.R.</th>
                        <th>Title</th>
                        <th>Parent category</th>
                        <th>Added on <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.0915 4.50796L3.99983 2.59129L5.90816 4.50796C5.98563 4.58606 6.0778 4.64806 6.17935 4.69037C6.2809 4.73267 6.38982 4.75446 6.49983 4.75446C6.60984 4.75446 6.71876 4.73267 6.82031 4.69037C6.92186 4.64806 7.01403 4.58606 7.0915 4.50796C7.1696 4.43049 7.2316 4.33832 7.2739 4.23677C7.31621 4.13522 7.33799 4.0263 7.33799 3.91629C7.33799 3.80628 7.31621 3.69736 7.2739 3.59581C7.2316 3.49426 7.1696 3.40209 7.0915 3.32462L4.5915 0.824624C4.51403 0.746517 4.42186 0.684522 4.32031 0.642214C4.21876 0.599907 4.10984 0.578125 3.99983 0.578125C3.88982 0.578125 3.7809 0.599907 3.67935 0.642214C3.5778 0.684522 3.48563 0.746517 3.40816 0.824624L0.908162 3.32462C0.751243 3.48154 0.663086 3.69437 0.663086 3.91629C0.663086 4.13821 0.751243 4.35104 0.908162 4.50796C1.06508 4.66488 1.27791 4.75303 1.49983 4.75303C1.72175 4.75303 1.93458 4.66488 2.0915 4.50796ZM5.90816 8.32462L3.99983 10.2413L2.0915 8.32462C2.0138 8.24692 1.92156 8.18529 1.82004 8.14324C1.71852 8.10119 1.60971 8.07955 1.49983 8.07955C1.38995 8.07955 1.28114 8.10119 1.17962 8.14324C1.0781 8.18529 0.985861 8.24692 0.908162 8.32462C0.830464 8.40232 0.768829 8.49456 0.726779 8.59608C0.684729 8.6976 0.663086 8.80641 0.663086 8.91629C0.663086 9.02617 0.684729 9.13498 0.726779 9.2365C0.768829 9.33802 0.830464 9.43026 0.908162 9.50796L3.40816 12.008C3.48563 12.0861 3.5778 12.1481 3.67935 12.1904C3.7809 12.2327 3.88982 12.2545 3.99983 12.2545C4.10984 12.2545 4.21876 12.2327 4.32031 12.1904C4.42186 12.1481 4.51403 12.0861 4.5915 12.008L7.0915 9.50796C7.24841 9.35104 7.33657 9.13821 7.33657 8.91629C7.33657 8.69437 7.24841 8.48154 7.0915 8.32462C6.93458 8.1677 6.72175 8.07955 6.49983 8.07955C6.27791 8.07955 6.06508 8.1677 5.90816 8.32462Z" fill="#9E9E9E"/>
                            </svg>
                            </th>
                        <th>Actions</th>
                    </tr>
                    <ListItem num="1" title="Title Name" parentCategory="Livestock" date="Dec 2, 2020" viewCont={viewList} updateCont={updateList} delCount={delList} />
                    <ListItem num="2" title="Title Name" parentCategory="Livestock" date="Dec 2, 2020" viewCont={viewList} updateCont={updateList} delCount={delList} />
                    <ListItem num="3" title="Title Name" parentCategory="Livestock" date="Dec 2, 2020" viewCont={viewList} updateCont={updateList} delCount={delList} />
                    <ListItem num="4" title="Title Name" parentCategory="Livestock" date="Dec 2, 2020" viewCont={viewList} updateCont={updateList} delCount={delList} />
                    <ListItem num="5" title="Title Name" parentCategory="Livestock" date="Dec 2, 2020" viewCont={viewList} updateCont={updateList} delCount={delList} />
                    <ListItem num="6" title="Title Name" parentCategory="Livestock" date="Dec 2, 2020" viewCont={viewList} updateCont={updateList} delCount={delList} />
                </table>
        </div> 

            
            <div className="modal" id="addSubCategory" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header px-4">
                        <h5 className="modal-title" id="#addCategorylabel">Create a new category</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div className="modal-body px-4 py-5">
                            <div>
                                <form className="pb-2">
                                    <div className="saveInput">
                                        <label className="text-uppercase">CATEGORY  Name</label>
                                        <div className="d-flex">
                                            <input type="Livestock" id="" />
                                            <button className="btn border-0">Save</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`modal ${getViewClass}`} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header px-4">
                        <h5 className="modal-title" id="#addCategorylabel">View List Item</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{setViewClass('hideModal')}}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div className="modal-body px-4 py-5">
                            <div>
                                Here is the List Data
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div className={`modal ${getUpdateClass}`} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header px-4">
                        <h5 className="modal-title" id="#addCategorylabel">Update List Item</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{setUpdateClass('hideModal')}}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div className="modal-body px-4 py-5">
                            <div>
                                <form className="pb-2">
                                    <div className="saveInput">
                                        <label className="text-uppercase">Title</label>
                                        <div className="d-flex">
                                            <input type="Livestock" id="" />
                                        </div>
                                    </div>
                                    <div className="saveInput pt-1">
                                        <label className="text-uppercase">Parent Category</label>
                                        <div className="d-flex">
                                            <select className="form-control form-control-lg">
                                                <option>Livestock</option>
                                                <option>Other Option 1</option>
                                                <option>Other Option 2</option>
                                                <option>Other Option 3</option>
                                            </select>
                                        </div>

                                        


                                    </div>
                                    <div className="saveInput pt-1">
                                        <label className="text-uppercase">Date</label>
                                        <div className="d-flex">
                                            <input type="Livestock" id="" />
                                        </div>
                                    </div>
                                    <div className="saveInput pt-2 float-right">
                                        <button className="btn border-0 ">Save</button>
                                    </div>
                                </form>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>

            <div className={`modal ${getDelClass}`} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header px-4">
                        <h5 className="modal-title" id="#addCategorylabel">Delete List Item</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{setDelClass('hideModal')}}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div className="modal-body px-4 py-5">
                            <div className="text-center">
                                <div>
                                    <h6 className="text-uppercase">Are you sure you want to delete this item ?</h6>
                                </div>
                                <div>
                                    <button className="btn btn-custom mr-2" onClick={()=>{setDelClass('hideModal')}}>No</button>
                                    <button className="btn btn-custom" id="whiteBtn">Yes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            
        </>
    )
}

export default SubCategoryList
