import React from 'react'
import Notification from '../../../shared/components/notification/notification'

const Notifications = () => {
    return (
        <div className="notificationCol bg-white border-radius-4">
            <h6 className="px-3 pt-3 pb-3 mb-0">Notifications</h6>
            <div className="media mb-2">
                <Notification name="Mitchell" min="26" color="#AD0000" />
            </div>
            <div className="media mb-2 active">
                <Notification name="Mitchell" min="26" color="#00AD83" />
            </div>
            <div className="media mb-2">
                <Notification name="Robert" min="26" color="#AD0072" />
            </div>
            <div className="media mb-2">
                <Notification name="Mitchell" min="26" color="#AD0000" />
            </div>
            <div className="media mb-2">
                <Notification name="Mitchell" min="26" color="#AD0000" />
            </div>
            <div className="media mb-2">
                <Notification name="Mitchell" min="26" color="#0030AD" />
            </div>
            <div className="media mb-2">
                <Notification name="Mitchell" min="26" color="#AD0000" />
            </div>
            <div className="media mb-2">
                <Notification name="Mitchell" min="26" color="#AD0000" />
            </div>
        </div>
    )
}

export default Notifications
