import "./searchForm.css";

const SearchForm = () => {
    return (
        <section className="search-form-holder inner-pages-search">
            <div className="container">
                <div className="form-wrapper">
                    <form className="search-form d-flex align-items-center justify-content-between" action="" method="post">
                        <div className="form-group full-w">
                            <div className="input-search-holder">
                                <input type="text" className="form-control field-des" placeholder="Update Keyword" />
                                <button className="btn btn-search"><i className="fa fa-search" aria-hidden="true"></i></button>
                            </div>
                        </div>
                        <div className="form-group d-flex align-items-center custom-w">
                            <span className="input-group-addon">
                                <img alt="" src={require("../../../assets/images/category.png").default} className="img-fluid" />
                            </span>
                            <select id="categories" name="categories" className="field-des " form="categoriesform">
                                <option value="">All categories</option>
                                <option value="">category 1</option>
                                <option value="">category 2</option>
                            </select>
                        </div>
                        <div className="form-group d-flex align-items-center custom-w">
                            <span className="input-group-addon">
                                <img alt="" src={require("../../../assets/images/pin.png").default} className="img-fluid" />
                            </span>
                            <select id="location" name="locations" className="field-des" form="locationform">
                                <option value="">All Locations</option>
                                <option value="">location 1</option>
                                <option value="">location 2</option>
                            </select>
                        </div>
                        <div className="form-group d-flex align-items-center custom-w">
                            <span className="input-group-addon">
                                <img alt="" src={require("../../../assets/images/arrows.png").default} className="img-fluid" />
                            </span>
                            <select id="distance" name="distance" className="field-des" form="distanceform">
                                <option value="">100kms</option>
                                <option value="">80kms</option>
                                <option value="">70kms</option>
                            </select>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
}

export default SearchForm;