import './productItem.css'

const ProductItem = ({ path, title, location, price, check }) => {

    const rend = () => {
        if (check) {
            return (
                <div className="product-item d-flex flex-row flex-lg-nowrap flex-md-wrap flex-sm-wrap flex-xs-wrap">
                    <div className="img-wrapper">
                        <img alt="" src={path} className="img-fluid" />
                    </div>
                    <div className="item-content d-flex flex-row align-items-center justify-content-between">
                        <div className="content-detail">
                            <h2 className="mb-2">{title}</h2>
                            <span className="item-address d-flex align-items-center mb-2">
                                <img alt="" src={require("../../../assets/images/location (2).png").default} className="img-fluid" />
                                <p>{location}</p>
                            </span>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus cras ullamcorper pretium fermentum facilisi turpis. Sit viverra nascetur.</p>
                        </div>
                        <div className="price-wrapper d-flex flex-column align-items-start justify-content-between">
                            <div className="item-price mb-2">
                                <p>Starting from<br />
                                    <strong>${price}</strong></p>
                            </div>
                            <div className="item-btn">
                                <button type="button" className="btn btn-custom">Shop now</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="product-item">
                    <div className="img-wrapper">
                        <img alt="" src={path} className="img-fluid" />
                    </div>
                    <div className="item-content">
                        <h2 className="mb-2">{title}</h2>
                        <span className="item-address d-flex align-items-center mb-2">
                            <img alt="" src={require("../../../assets/images/location (2).png").default} className="img-fluid" />
                            <p>{location}</p>
                        </span>
                        <div className="price-wrapper d-flex flex-row align-items-center justify-content-between">
                            <div className="item-price">
                                <p>Starting from<br />
                                    <strong>${price}</strong>
                                </p>
                            </div>
                            <div className="item-btn">
                                <button type="button" className="btn btn-custom">Shop now</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        rend()
    )
}

export default ProductItem
