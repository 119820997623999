import './pageBanner.css'


const PageBanner = ({ check, title, content }) => {

    const rend = () => {
        if (check) {
            return (
                <div className="page-banner d-flex justify-content-center align-items-center position-relative">
                    <div className="overlay black-overlay">&nbsp;</div>
                    <div className="d-flex flex-column text-center text-white position-relative px-3">
                        <h1 className="mb-3">How can we help you</h1>
                        <form className="faq-search-form position-relative">
                            <span className="search-icon">
                                <i className="fa fa-search"></i>
                            </span>
                            <input className="form-control" type="text" placeholder="Search by keyword" />
                        </form>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="page-banner d-flex justify-content-center align-items-center position-relative">
                    <div className="overlay black-overlay">&nbsp;</div>
                    <div className="d-flex flex-column text-center text-white position-relative px-3">
                        <h1 className="mb-3">{title}</h1>
                        <p>{content}</p>
                    </div>
                </div>
            )
        }
    }


    return (
        <>
            {rend()}
        </>
    )
}

export default PageBanner
