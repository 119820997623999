import { Link } from "react-router-dom";

const AdminListing = () => {
    return (
        <>
            <div className="dashContentHeader">
                <h5 className="dashHeading mb-0">My Listing</h5>
            </div>
            <div className="dashboardCardRow">
                <div className="input-group mb-4 search-menu">
                    <input type="text" className="form-control" placeholder="Search listing..." />
                    <div className="input-group-append">
                        <span className="input-group-text">
                            <i className="fa fa-search" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>
                <div className="listingTableRow">
                    <div className="listingTableHeader d-flex flex-wrap justify-content-between align-items-center pb-4 ">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                            <label className="custom-control-label" for="customCheck1">Showing 16 products</label>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <button className="border-0 deleteButton mr-4">
                                <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.33398 3.66667V2.33333C3.33398 1.97971 3.47446 1.64057 3.72451 1.39052C3.97456 1.14048 4.3137 1 4.66732 1H7.33398C7.68761 1 8.02674 1.14048 8.27679 1.39052C8.52684 1.64057 8.66732 1.97971 8.66732 2.33333V3.66667M10.6673 3.66667V13C10.6673 13.3536 10.5268 13.6928 10.2768 13.9428C10.0267 14.1929 9.68761 14.3333 9.33398 14.3333H2.66732C2.3137 14.3333 1.97456 14.1929 1.72451 13.9428C1.47446 13.6928 1.33398 13.3536 1.33398 13V3.66667H10.6673Z" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M4.66602 7V11" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M7.33398 7V11" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                Delete
                            </button>
                            <div className="d-flex align-items-center shortListedDropdown">
                                <label className="mb-0">Sort by</label>
                                <div className="dropdown">
                                    <button className="border-0 btn-link dropdown-toggle card-link"
                                        type="button" id="dropdownMenu1" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        Newset update
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenu1">
                                        <Link className="dropdown-item" to={window.location.pathname}>Action</Link>
                                        <Link className="dropdown-item" to={window.location.pathname}>action2</Link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table customDashboardTable">
                            <tr>
                                <th>Listing Name</th>
                                <th>Category</th>
                                <th>Subcategory</th>
                                <th>Price</th>
                                <th style={{ maxWidth: "100px" }}>Minimum Offer Amount</th>
                                <th style={{ maxWidth: "100px" }}>No. of
                                    Offers</th>
                                <th>Status</th>
                                <th>Seller Info</th>
                                <th>Actions</th>
                            </tr>


                            <tr>
                                <td>
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="customCheck2" checked />
                                        <label className="custom-control-label" for="customCheck2">John Deere S680</label>
                                    </div>
                                </td>
                                <td>Machinery</td>
                                <td>Harvesting
                                    & Headers</td>
                                <td>$50</td>
                                <td>20,000</td>
                                <td>12</td>
                                <td><button className="btn soldBtn">Sold</button></td>
                                <td>Seller Name <span>seller@domain.com</span></td>
                                <td><div className="dropdown">
                                    <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <svg width="22" height="6" viewBox="0 0 22 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="3" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="11" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="19" cy="3" r="3" fill="#C4C4C4" />
                                        </svg>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                        <Link className="dropdown-item" to={window.location.pathname}>Action</Link>
                                        <Link className="dropdown-item" to={window.location.pathname}>Another action</Link>
                                        <Link className="dropdown-item" to={window.location.pathname}>Something else here</Link>
                                    </div>
                                </div></td>
                            </tr>

                            <tr>
                                <td>
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="customCheck3" />
                                        <label className="custom-control-label" for="customCheck3">John Deere S680</label>
                                    </div>
                                </td>
                                <td>Machinery</td>
                                <td>Harvesting
                                    & Headers</td>
                                <td>$50</td>
                                <td>20,000</td>
                                <td>12</td>
                                <td><button className="btn ExpiredBtn">Expired</button></td>
                                <td>Seller Name <span>seller@domain.com</span></td>
                                <td><div className="dropdown">
                                    <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <svg width="22" height="6" viewBox="0 0 22 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="3" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="11" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="19" cy="3" r="3" fill="#C4C4C4" />
                                        </svg>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                        <Link className="dropdown-item" to={window.location.pathname}>Action</Link>
                                        <Link className="dropdown-item" to={window.location.pathname}>Another action</Link>
                                        <Link className="dropdown-item" to={window.location.pathname}>Something else here</Link>
                                    </div>
                                </div></td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="customCheck4" />
                                        <label className="custom-control-label" for="customCheck4">John Deere S680</label>
                                    </div>
                                </td>
                                <td>Machinery</td>
                                <td>Harvesting
                                    & Headers</td>
                                <td>$50</td>
                                <td>20,000</td>
                                <td>12</td>
                                <td><button className="btn biddingBtn">Bidding</button></td>
                                <td>Seller Name <span>seller@domain.com</span></td>
                                <td><div className="dropdown">
                                    <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <svg width="22" height="6" viewBox="0 0 22 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="3" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="11" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="19" cy="3" r="3" fill="#C4C4C4" />
                                        </svg>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                        <Link className="dropdown-item" to={window.location.pathname}>Action</Link>
                                        <Link className="dropdown-item" to={window.location.pathname}>Another action</Link>
                                        <Link className="dropdown-item" to={window.location.pathname}>Something else here</Link>
                                    </div>
                                </div></td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="customCheck5" checked />
                                        <label className="custom-control-label" for="customCheck5">John Deere S680</label>
                                    </div>
                                </td>
                                <td>Machinery</td>
                                <td>Harvesting
                                    & Headers</td>
                                <td>$50</td>
                                <td>20,000</td>
                                <td>12</td>
                                <td><button className="btn listedBtn">Listed</button></td>
                                <td>Seller Name <span>seller@domain.com</span></td>
                                <td><div className="dropdown">
                                    <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <svg width="22" height="6" viewBox="0 0 22 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="3" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="11" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="19" cy="3" r="3" fill="#C4C4C4" />
                                        </svg>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                        <Link className="dropdown-item" to={window.location.pathname}>Action</Link>
                                        <Link className="dropdown-item" to={window.location.pathname}>Another action</Link>
                                        <Link className="dropdown-item" to={window.location.pathname}>Something else here</Link>
                                    </div>
                                </div></td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="customCheck7" />
                                        <label className="custom-control-label" for="customCheck7">John Deere S680</label>
                                    </div>
                                </td>
                                <td>Machinery</td>
                                <td>Harvesting
                                    & Headers</td>
                                <td>$50</td>
                                <td>20,000</td>
                                <td>12</td>
                                <td><button className="btn soldBtn">sold</button></td>
                                <td>Seller Name <span>seller@domain.com</span></td>
                                <td><div className="dropdown">
                                    <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <svg width="22" height="6" viewBox="0 0 22 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="3" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="11" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="19" cy="3" r="3" fill="#C4C4C4" />
                                        </svg>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                        <Link className="dropdown-item" to={window.location.pathname}>Action</Link>
                                        <Link className="dropdown-item" to={window.location.pathname}>Another action</Link>
                                        <Link className="dropdown-item" to={window.location.pathname}>Something else here</Link>
                                    </div>
                                </div></td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="customCheck8" />
                                        <label className="custom-control-label" for="customCheck8">John Deere S680</label>
                                    </div>
                                </td>
                                <td>Machinery</td>
                                <td>Harvesting
                                    & Headers</td>
                                <td>$50</td>
                                <td>20,000</td>
                                <td>12</td>
                                <td><button className="btn soldBtn">sold</button></td>
                                <td>Seller Name <span>seller@domain.com</span></td>
                                <td><div className="dropdown">
                                    <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <svg width="22" height="6" viewBox="0 0 22 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="3" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="11" cy="3" r="3" fill="#C4C4C4" />
                                            <circle cx="19" cy="3" r="3" fill="#C4C4C4" />
                                        </svg>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                        <Link className="dropdown-item" to={window.location.pathname}>Action</Link>
                                        <Link className="dropdown-item" to={window.location.pathname}>Another action</Link>
                                        <Link className="dropdown-item" to={window.location.pathname}>Something else here</Link>
                                    </div>
                                </div></td>
                            </tr>
                        </table>
                    </div>


                    <div className="tableArrowScrollar">
                        <ul className="d-flex align-items-center justify-content-end">
                            <li className="mr-4">
                                <button className="border-0 btn-link"><svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M24 11H2" stroke="#C4C4C4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M11 2L2 11L11 20" stroke="#C4C4C4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                </button>
                            </li>
                            <li>
                                <button className="border-0 btn-link">
                                    <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 11H24" stroke="#FF7E06" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M15 2L24 11L15 20" stroke="#FF7E06" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>


                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminListing;