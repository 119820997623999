import { Link } from "react-router-dom";

const Card = ({styleName, title, num, lnk}) => {
    return (
        <div className={`card dashboardCard shadow border-0 ${styleName} overflow-hidden`}>
                <div className="card-body text-center">
                    <h4 className="card-title mb-4 pb-2">{title}</h4>
                    <h3 className="card-text mb-4">{num}</h3>
                    <Link to={lnk} className="card-link">View all</Link>
                </div>
        </div>
    )
}

export default Card
