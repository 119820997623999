import { Link } from "react-router-dom";
import "./footer.css";

const Footer = () => {
    return (
        <footer id="DefaultFooter" className="bg-black">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                        <div className="footer-content">
                            <h1>CATEGORIES</h1>
                            <ul className="list-unstyled">
                                <li>
                                    {/* <div className="dropdown">
                                        <button className="btn btn-primary btn-lg dropdown-toggle" type="button" data-toggle="dropdown">
                                            Cattle
                                        </button>
                                        <div className="dropdown-menu">
                                            <Link className="dropdown-item" to={window.location.pathname}>Product 01</Link>
                                            <Link className="dropdown-item" to={window.location.pathname}>Product 02</Link>
                                            <Link className="dropdown-item" to={window.location.pathname}>Product 03</Link>
                                        </div>
                                    </div> */}

                                    <Link to={window.location.pathname}>Cattle</Link></li>
                                <li><Link to={window.location.pathname}>Horses</Link></li>
                                <li><Link to={window.location.pathname}>Llamas</Link></li>
                                <li><Link to={window.location.pathname}>Alpacas</Link></li>
                                <li><Link to={window.location.pathname}>Chickens</Link></li>
                                <li><Link to={window.location.pathname}>Turkeys</Link></li>
                                <li><Link to={window.location.pathname}>Ducks</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                        <div className="footer-content">
                            <h1>Menu</h1>
                            <ul className="list-unstyled">
                                <li><Link to="/about-us">About</Link></li>
                                <li><Link to="/help"> Help/FAQ</Link></li>
                                <li><Link to="/contact-us">Contact</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                        <div className="footer-content">
                            <h1>BIDS</h1>
                            <ul className="list-unstyled">
                                <li><Link to={window.location.pathname}>Privacy policy</Link></li>
                                <li><Link to={window.location.pathname}>Conditions of use</Link></li>
                                <li><Link to={window.location.pathname}>Conditions of Sale</Link></li>
                                <li><Link to="/help">FAQs</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                        <div className="footer-content">
                            <h1>Social Links</h1>
                            <div className="social-links">
                                <Link target="_blank" to={window.location.pathname} className="lnk-fb">
                                    <i className="fa fa-twitter"></i>
                                </Link>
                                <Link target="_blank" to={window.location.pathname} className="lnk-yt">
                                    <i className="fa fa-facebook"></i>
                                </Link>
                                <Link target="_blank" to={window.location.pathname} className="lnk-wapp">
                                    <i className="fa fa-instagram"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <p>Bid.com Copyright © 2020. All rights reserved</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;