import { Link } from "react-router-dom";
import pro1 from "../../assets/images/pro-1.png";
import pp1 from "../../assets/images/pp1.png";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ClaimListing from "../modals/claimListing";
import ClaimListingVerified from "../modals/claimListingVerified";
import "./productDetails.css";

const ProductDetails = () => {

    //Owl Carousel Settings
    const options = {
        loop: true,
        interval: 5000,
        margin: 10,
        responsiveClass: true,
        responsive: {
            0: {
                items: 3,
                nav: true
            },
            600: {
                items: 4,
                nav: false
            },
            1000: {
                items: 5,
                nav: true,
                loop: false
            }
        }
    }

    return (
        <>
            <div id="wrapper" className="d-flex justify-content-between">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="col-12 bg-orange py-3  mb-5 d-flex justify-content-between align-items-center border-radius4">
                                <p className="text-white mb-0 font-18 "> This listing is unclaimed. If it belongs to you, click the button to claim</p>
                                <div>
                                    <button type="button" data-toggle="modal" data-target="#claim-listing" className="claim-listing">Claim Listing</button>
                                    <span>
                                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.2812 3.71875L3.71875 13.2812" stroke="white" stroke-opacity="0.7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M13.2812 13.2812L3.71875 3.71875" stroke="white" stroke-opacity="0.7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-7">
                            <div className="main-image">
                                <img className="mb-4" src={pro1} alt="" />
                            </div>

                            <div className="pro-slider">
                                <div className="row blog">
                                    <div className="col-md-12">
                                        <div id="blogCarousel" className="carousel slide" data-ride="carousel">

                                            {/* <div className="owl-carousel owl-theme"> */}
                                            <OwlCarousel className="slider-items owl-carousel" {...options}>
                                                <div className="item">
                                                    <Link to={window.location.pathname}>
                                                        <img src={pp1} alt="" style={{ maxWidth: "100%" }} />
                                                    </Link>
                                                </div>
                                                <div className="item">
                                                    <Link to={window.location.pathname}>
                                                        <img src={pp1} alt="" style={{ maxWidth: "100%" }} />
                                                    </Link>
                                                </div>
                                                <div className="item">
                                                    <Link to={window.location.pathname}>
                                                        <img src={pp1} alt="" style={{ maxWidth: "100%" }} />
                                                    </Link>
                                                </div>
                                                <div className="item">
                                                    <Link to={window.location.pathname}>
                                                        <img src={pp1} alt="" style={{ maxWidth: "100%" }} />
                                                    </Link>
                                                </div>
                                                <div className="item">
                                                    <Link to={window.location.pathname}>
                                                        <img src={pp1} alt="" style={{ maxWidth: "100%" }} />
                                                    </Link>
                                                </div>
                                                <div className="item">
                                                    <Link to={window.location.pathname}>
                                                        <img src={pp1} alt="" style={{ maxWidth: "100%" }} />
                                                    </Link>
                                                </div>
                                                <div className="item">
                                                    <Link to={window.location.pathname}>
                                                        <img src={pp1} alt="" style={{ maxWidth: "100%" }} />
                                                    </Link>
                                                </div>
                                                <div className="item">
                                                    <Link to={window.location.pathname}>
                                                        <img src={pp1} alt="" style={{ maxWidth: "100%" }} />
                                                    </Link>
                                                </div>
                                                <div className="item">
                                                    <Link to={window.location.pathname}>
                                                        <img src={pp1} alt="" style={{ maxWidth: "100%" }} />
                                                    </Link>
                                                </div>
                                                <div className="item">
                                                    <Link to={window.location.pathname}>
                                                        <img src={pp1} alt="" style={{ maxWidth: "100%" }} />
                                                    </Link>
                                                </div>
                                            </OwlCarousel>

                                            {/* </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 product-details">
                            <div className="b-crum">
                                <ul className="m-0 p-0 d-flex"><li>Category</li><li>Livestock</li><li>Cattle</li></ul>
                            </div>
                            <div className="product-name mb-4">Angus Bulls</div>
                            <div className="row mb-3">
                                <div className="col-lg-4 detail-label">Location</div>
                                <div className="col-lg-8 detail-value">Fairhope, Alabama, United States</div>
                            </div>
                            <div className="row mb-3">   <div className="col-lg-4 detail-label">Species</div><div className="col-lg-8 detail-value">Angus Bulls</div></div>
                            <div className="row mb-3">  <div className="col-lg-4 detail-label">Breed</div><div className="col-lg-8 detail-value">Droughtmaster</div></div>
                            <div className="row mb-3"> <div className="col-lg-4 detail-label">Sex</div><div className="col-lg-8 detail-value">Male</div></div>
                            <div className="row mb-3"> <div className="col-lg-4 detail-label">Age</div><div className="col-lg-8 detail-value">1 Year</div></div>
                            <div className="row mb-3"> <div className="col-lg-4 detail-label">Weight Range </div><div className="col-lg-8 detail-value">500-500 kg</div></div>
                            <div className="row mb-3"><div className="col-lg-4 detail-label">Delivery</div><div className="col-lg-8 detail-value">Freightlancer</div></div>

                            <div className="listingTableHeader d-flex justify-content-between align-items-center">
                                <div className="custom-control custom-checkbox pb-5">
                                    <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                    <label className="custom-control-label" for="customCheck1">Request Free freight Quote</label>
                                </div>
                            </div>
                            <div className="offerButtons d-flex align-items-center justify-content-between flex-wrap">
                                <h2 className="m-0 font-weight-bold">$1600.00</h2>
                                <button type="button" data-toggle="modal" data-target="#make-offer" className="btn btn-custom text-white">Make An Offer</button>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4 pt-2 align-items-center">
                        <div className="col-lg-9">
                            <div className="descriptionInfo">
                                <h5 className="mainHeading mb-3 text-dark">Description</h5>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                    when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    It has survived not only five centuries, but also the leap into electronic typesetting, remaining
                                    essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
                                    Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including
                                    versions of Lorem Ipsum.t is a long established fact that a reader will be distracted by the readable content
                                    of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
                                    distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.
                                    Many desktop publishing packages and web page editors now. </p>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="descriptionAddress">
                                <p className="mb-2">Seller</p>
                                <h4 className="mb-2">John A</h4>
                                <span className="descriptionLocation mb-1">
                                    <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.65304 0.506052L9.65312 0.506049C15.0415 0.316238 19.4689 4.62286 19.4689 9.96538C19.4689 10.5789 19.4104 11.1775 19.2983 11.7615L19.2975 11.7659L19.2975 11.766L19.2975 11.7661L19.2975 11.7661L19.2975 11.7662L19.2975 11.7662L19.297 11.7688L19.2945 11.7816C19.2922 11.7935 19.2886 11.812 19.2835 11.8366C19.2734 11.886 19.2576 11.9601 19.2355 12.0557L19.2346 12.0597C19.0887 12.7159 18.8748 13.3421 18.598 13.9339L18.594 13.9424L18.5904 13.951C17.6161 16.2551 15.3515 20.1494 10.2247 24.4007C10.0848 24.5156 9.88719 24.5122 9.75664 24.4028L9.7549 24.4014C4.62735 20.1446 2.36267 16.25 1.39353 13.9562L1.39052 13.9491L1.38729 13.9421C1.11378 13.3468 0.894741 12.7141 0.74906 12.064L0.749209 12.064L0.746204 12.052C0.722708 11.9581 0.707931 11.8885 0.699325 11.8444C0.695021 11.8224 0.69226 11.8067 0.690722 11.7976L0.68936 11.7894L0.689293 11.7889L0.689282 11.7889L0.689192 11.7882L0.687837 11.7787L0.685695 11.7675C0.519648 10.8942 0.466311 9.98422 0.558753 9.04617L0.558852 9.04515C1.0116 4.35208 4.93362 0.671476 9.65304 0.506052ZM4.64321 10.0832C4.64321 13.034 7.03679 15.4244 9.98937 15.4244C12.942 15.4244 15.3355 13.034 15.3355 10.0832C15.3355 7.1324 12.9419 4.74201 9.98937 4.74201C7.03679 4.74201 4.64321 7.1324 4.64321 10.0832Z" fill="black" stroke="black" />
                                    </svg>
                                    Fairhope, Alabama, <br />United States
                                </span>
                                <span>Member Since  July 4, 2019</span>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="mt-4 pt-2">
                        <h5 className="mainHeading mb-4 text-dark">Additional Information</h5>
                        <div className="addtnlInfo d-flex justify-content-between">
                            <div className="row w-100">
                                <div className="col-lg-5">
                                    <div className="row mb-3">
                                        <div className="col-lg-7">Registration Number</div>
                                        <div className="col-lg-5"><p className="mb-0">AAA 17916048</p>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-lg-7">Accreditations</div>
                                        <div className="col-lg-5"><p className="mb-0">MAS</p></div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-lg-7">Teeth</div>
                                        <div className="col-lg-5"><p className="mb-0">Not Mounthed</p></div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-lg-7">HGP treated</div>
                                        <div className="col-lg-5"><p className="mb-0">No</p></div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-lg-7">Fertility</div>
                                        <div className="col-lg-5"><p className="mb-0">Wil Guarantee Fertile</p></div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-lg-7">Scrotal Circm</div>
                                        <div className="col-lg-5"><p className="mb-0">Average 38.1 cm</p></div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-lg-offset-2">

                                </div>
                                <div className="col-lg-5">
                                    <div className="row mb-3">
                                        <div className="col-lg-6">Pasture/Feed</div>
                                        <div className="col-lg-6"><p className="mb-0">Limited Grain & Pasture</p>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-lg-6">Frame</div>
                                        <div className="col-lg-6"><p className="mb-0">Frame</p>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-lg-6">Condition</div>
                                        <div className="col-lg-6"><p className="mb-0">Condition</p>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-lg-6">Vaccinations</div>
                                        <div className="col-lg-6"><p className="mb-0">Vira. Shield 6+ VL5 , MultiMin 90</p>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-lg-6">Tick Status</div>
                                        <div className="col-lg-6"><p className="mb-0">Ticket Free Zone</p>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-lg-6">Horns</div>
                                        <div className="col-lg-6"><p className="mb-0">Polled</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <hr />
                    <div className="mt-4 pt-2">
                        <h5 className="mainHeading mb-4 text-dark">Location</h5>
                        <div className="map mb-5">
                            <iframe title="google map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1865358.580944106!2d133.18052404266925!3d-24.056496013602285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2b321944be8f1331%3A0x50217a82a254fd0!2sAlice%20Springs%20NT%200870%2C%20Australia!5e0!3m2!1sen!2sin!4v1610954679338!5m2!1sen!2sin" width="100%" height="450" frameborder="0" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0">

                            </iframe>
                        </div>
                    </div>
                </div>
            </div>
            <ClaimListing />
            <ClaimListingVerified />
        </>
    );
}

export default ProductDetails;