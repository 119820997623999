
const ClaimListingVerified = () => {
    return (
        <div id="claim-listing-verified" className="modal fades" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-width" role="document">
                <div className="modal-content ">
                    <div className="modal-body p-5">
                        <h4 className="text-center  modal-heading">Verification Successful</h4>
                        <div className="d-flex justify-content-center my-5">
                            <svg width="133" height="133" viewBox="0 0 133 133" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="66.5" cy="66.5" r="66.5" fill="#42C31D" />
                                <path d="M98.625 46.877L58.375 87.1252L38.25 67.002" stroke="white" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClaimListingVerified;
