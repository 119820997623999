import Header from "./header/header";
import Footer from "./footer/footer";
import { connect } from 'react-redux';
import "./default.css";

const Default = (props) => {
    return (
        <div className="defaultLayout">
            <Header />
            {props.children}
            <Footer />
        </div>
    );
}

// export default Unauth
const mapStateToProps = state => ({
    shared: state.shared
})
export default connect(mapStateToProps, {})(Default)